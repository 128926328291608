import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const { getSubtenantUrl } = SlugGetters;

const getMy = async (options = {}) => {
  const params = options ? { ...options } : null;
  try {
    const response = await axios.get(`${getSubtenantUrl()}/notification/my`, {
      params,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const notSeen = async data => {
  try {
    const response = await axios.get(`${getSubtenantUrl()}/notification/not-seen`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const seeAll = async data => {
  try {
    const response = await axios.post(`${getSubtenantUrl()}/notification/see-all`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const clearAll = async data => {
  try {
    const response = await axios.post(`${getSubtenantUrl()}/notification/clear-all`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const seeByIds = async data => {
  try {
    const response = await axios.post(`${getSubtenantUrl()}/notification/see-by-ids`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const updateCondition = async (notificationId, updatedData) => {
  try {
    const response = await axios.put(`${getSubtenantUrl()}/notification/${notificationId}/condition`, updatedData);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

export default {
  getMy,
  notSeen,
  seeAll,
  seeByIds,
  updateCondition,
  clearAll,
};
