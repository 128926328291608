<template>
  <div>
    <InputOptions :fieldId="fieldId" />
    <LabelOptions :fieldId="fieldId" />
    <DescriptionOptions :fieldId="fieldId" />
  </div>
</template>

<script>
import InputOptions from './InputOptions.vue';
import LabelOptions from './LabelOptions.vue';
import DescriptionOptions from './DescriptionOptions.vue';

export default {
  name: 'AppeareanceOptionsIndex',
  props: ['fieldId'],
  components: {
    InputOptions,
    LabelOptions,
    DescriptionOptions,
  },
};
</script>
