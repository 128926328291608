<template>
  <div>
    <span v-html="structureOptions?.defaultValue"></span>
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  props: ['structureOptions'],
  mounted() {
    this.inputValue = this?.structureOptions?.defaultValue ?? '';
  },
};
</script>
