<template>
  <div>
    <div v-if="workflowsLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <div class="saas_sections">
        <div class="sidebar">
          <div class="actions__timeline">
            <workflow-timeline
              :workflowGroupId="workflowGroupId"
              :workflowId="workflowId"
              :workflows="workflows?.workflows ?? []"
              :selectedEventId="selectedEventId"
              @open:workflow="openWorkflow"
              @open:event="timelineSelectEvent"
              @autosave="toggleAutoSave"
            ></workflow-timeline>
          </div>
        </div>
        <div class="content-view">
          <div class="content-body">
            <WorkflowEvents
              :workflowGroupId="workflowGroupId"
              :workflowId="workflowId"
              :workflows="workflows?.workflows ?? []"
              :workflowName="workflows?.name"
              :workflowDescription="workflows?.description"
              :selectedEventId="selectedEventId"
              :triggersList="triggersList"
              :eventsList="eventsList"
              @open:event="selectEvent"
              @autosave="toggleAutoSave"
            />
          </div>
        </div>
      </div>

      <a-button class="autosave" @click="saveOnLeave" v-if="autoSaveButton && !this.disableSaveButton" size="large">
        <SaveOutlined /> Save now
      </a-button>
    </div>
  </div>
</template>

<script>
import { WorkflowActions, WorkflowGetters, WorkflowMutations } from '@workflow/shared/workflow.store';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

import { SlugGetters } from '@/slug.store';
import { SaveOutlined } from '@ant-design/icons-vue';
import WorkflowTimeline from './components/WorkflowTimeline.vue';
import WorkflowEvents from './components/WorkflowEvents.vue';

export default {
  async created() {
    await WorkflowActions.fetchWorkflows(this.workflowGroupId);
    window.addEventListener('beforeunload', this.saveOnLeave);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.saveOnLeave);
  },

  computed: {
    autoSave: WorkflowGetters.getAutoSave,
    autoSaveButton: WorkflowGetters.getAutoSaveButton,
    disableSaveButton: WorkflowGetters.getDisableSaveButton,

    workflowsLoading: WorkflowGetters.getWorkflowsLoading,
    saveWorkflowsLoading: WorkflowGetters.getSaveWorkflowsLoading,

    workflows: WorkflowGetters.getWorkflows,
    subtenantUrl: SlugGetters.getSubtenantUrl,
  },
  data() {
    return {
      loading: true,

      selectedEventId: null,

      workflowGroupId: this.$route.params?.workflowGroupId ?? 0,
      workflowId: this.$route.params?.workflowId ?? 0,

      triggersList: [
        {
          _id: 1,
          key: 'CREATED',
          name: 'Create',
          details: 'Triggers when an Entry is Created.',
          icon: '',
        },
        {
          _id: 3,
          key: 'UPDATED',
          name: 'Update',
          details: 'Triggers when an Entry is Updated.',
          icon: '',
        },
        {
          _id: 4,
          key: 'DELETED',
          name: 'Delete',
          details: 'Triggers when an Entry is Deleted.',
          icon: '',
        },
        {
          _id: 5,
          key: 'RUN LOCALLY',
          name: 'Manual Jump Trigger',
          details: 'Use this to continue a workflow jump.',
          icon: '',
        },
        {
          _id: 6,
          key: 'TIMED',
          name: 'Time Trigger',
          details: 'Triggers event at specific time.',
          icon: '',
        },
      ],

      eventsList: [
        {
          _id: 1,
          name: 'Send notification to role users',
          details: 'Send notification.',
          icon: 'BellOutlined',
          component: 'NotificationEvent',
        },
        {
          _id: 9,
          name: 'Send email',
          details: 'Send email.',
          icon: 'MailOutlined',
          component: 'EmailEvent',
        },
        {
          _id: 2,
          name: 'Validare',
          details: 'Validare campuri.',
          icon: 'ApiOutlined',
          component: 'ValidationEvent',
        },
        {
          _id: 11,
          name: 'Comparisons',
          details: 'Compare fields from 2 blueprints.',
          icon: 'DisconnectOutlined',
          component: 'comparisonEvent',
        },
        {
          _id: 3,
          name: 'Jump to another workflow',
          details: 'Salt la un alt workflow.',
          icon: 'PullRequestOutlined',
          component: 'JumpEvent',
        },
        {
          _id: 4,
          name: 'Catch conditional notification response',
          details: 'Catch conditional notification response.',
          icon: 'BranchesOutlined',
          component: 'CatchFeedbackEvent',
        },
        {
          _id: 5,
          name: 'Update fields',
          details: 'Update fields from workflow.',
          icon: 'FileProtectOutlined',
          component: 'UpdateFieldsEvent',
        },
        {
          _id: 8,
          name: 'Copy data to another Business Objects',
          details: 'Copy data to another Business Objects.',
          icon: 'CopyOutlined',
          component: 'CopyDataEvent',
        },
        {
          _id: 6,
          name: 'Change Entry Owner Role permissions',
          details: 'Change View and Modify permissions',
          icon: 'UnlockOutlined',
          component: 'UpdatePermissionsEvent',
        },
        {
          _id: 7,
          name: 'Enable / Disable dataflow',
          details: 'Enable / Disable dataflow.',
          icon: 'PoweroffOutlined',
          component: 'EnableDisableDataProvider',
        },
        {
          _id: 10,
          name: 'Exchange',
          details: 'Exchange',
          icon: 'EuroCircleOutlined',
          component: 'Exchange',
        },
      ],
    };
  },
  watch: {
    workflows: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue._id) {
          WorkflowMutations.SET_AUTO_SAVE_BUTTON(true);
          WorkflowActions.saveWorkflows(newValue);
        }
      },
    },
  },
  methods: {
    toggleAutoSave(value) {
      WorkflowMutations.SET_AUTO_SAVE(value);
    },
    timelineSelectEvent(eventId) {
      this.selectedEventId = eventId;
    },
    selectEvent(eventId) {
      this.selectedEventId = eventId;
    },
    async saveOnLeave() {
      await WorkflowActions.saveOnLeave();
    },

    async save(data) {
      await WorkflowActions.saveWorkflows(data);
    },

    openWorkflow(workflow) {
      if (workflow._id.toString() !== this.workflowId.toString()) {
        router.push({
          name: 'SelectWorkflow',
          params: {
            workflowGroupId: this.workflowGroupId,
            workflowId: workflow._id,
          },
        });
        this.workflowId = workflow._id;
      }
    },
  },
  components: {
    WorkflowTimeline,
    WorkflowEvents,
    SaveOutlined,
  },
};
</script>

<style scoped>
.autosave {
  background-color: #7b68ee;
  color: #fff;
  float: right;
  margin-right: 20px;
}
.autosave:hover {
  background-color: #5b43ea;
  color: #fff;
  border-color: transparent;
}
</style>
