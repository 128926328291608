<template>
  <a-drawer title="Unique constraints" width="500px" :open="visible">
    <h6>
      Fields:
      <help-icon-popover
        title="Unique constraints"
        content="Drag and drop fields from this list to create single or combinations of unique constraints."
      />
    </h6>
    <div class="currentConstraints bpFields mb-3" v-if="fields && fieldIds">
      <div class="badgeWrapper">
        <div class="constraintBadge bpField" v-for="(field, i) in fieldIds" :key="i" :data-fieldid="field" draggable="true" @dragstart="onDragStart">
          {{ getFieldNameFromId(field) }}
        </div>
      </div>
    </div>

    <hr />

    <div class="currentConstraints" v-if="uniqueConstraints">
      <a-button @click="() => uniqueConstraints.push([])" class="mb-2">Add constraint</a-button>
      <a-button @click="updateBlueprintUniqueConstraints" type="primary" class="mb-2 ml-2">Save changes</a-button>

      <div v-if="!uniqueConstraints?.length">No constraints added.</div>
      <div class="mb-1" v-else>Current constraints:</div>

      <div
        v-for="(constraint, i) in uniqueConstraints"
        :key="i"
        class="mb-1"
        @drop="onDrop"
        @dragenter="onDragEnter"
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        :data-listindex="i"
      >
        <a-card class="mb-1" :data-listindex="i">
          <a-button size="small" style="position: absolute; top: 5px; right: 5px" @click="removeConstraint(i)"><CloseOutlined /> </a-button>

          <div class="badgeWrapper" style="min-height: 33px" :data-listindex="i">
            <div v-for="(fieldId, j) in constraint" :key="j" style="display: flex; align-items: center" :data-listindex="i">
              <div class="constraintBadge dragOff" :data-listindex="i">
                {{ getFieldNameFromId(fieldId) }}
                <span :data-listindex="i" @click="removeConstraintItem(i, j)">X</span>
              </div>

              <span
                v-if="j < constraint.length - 1"
                :data-listindex="i"
                style="font-size: 9px; padding: 1px 3px; border: 1px solid #cccccc; border-radius: 4px; text-align: center; margin: 0 4px"
                >AND</span
              >
            </div>
          </div>
        </a-card>

        <span
          v-if="i < uniqueConstraints.length - 1"
          style="padding: 3px 6px; border: 1px solid #cccccc; border-radius: 4px; display: block; width: 50px; text-align: center; margin: 0 auto"
          >OR</span
        >
      </div>
    </div>
    <div v-else>
      <a-spin><LoadingOutlined spin style="fontsize: '24px'" /> </a-spin>
    </div>
  </a-drawer>
</template>

<script>
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { blueprintApi } from '@dataSystem/api';
import { FormBuilderGetters } from '@dataSystem/views/BlueprintFormBuilder/formBuilder.store';
import { message } from 'ant-design-vue';

export default {
  props: ['visible', 'blueprintId'],
  components: {
    LoadingOutlined,
    CloseOutlined,
  },
  data() {
    return {
      blueprint: null,
      uniqueConstraints: null,
    };
  },
  created() {
    this.fetchBlueprint();
  },
  computed: {
    fields: FormBuilderGetters.getFields,
    fieldIds() {
      const fields = [];
      if (this.fields) {
        this.fields.forEach(f => fields.push(f._id));
      }
      return fields;
    },
  },
  methods: {
    async fetchBlueprint() {
      const { blueprint } = await blueprintApi.getOne(this.blueprintId);
      this.blueprint = blueprint;

      if (this.blueprint?.uniqueConstraints && Array.isArray(this.blueprint.uniqueConstraints)) {
        this.uniqueConstraints = this.blueprint.uniqueConstraints;
      } else {
        this.uniqueConstraints = [];
      }
    },
    async updateBlueprintUniqueConstraints() {
      const updatedUniqueConstraints = await blueprintApi.patchUniqueConstraints(this.blueprintId, this.uniqueConstraints);
      this.blueprint.uniqueConstraints = updatedUniqueConstraints;
      message.success('Saved');
    },

    onDragStart(event) {
      const draggedItem = event.target.dataset.fieldid;
      event.dataTransfer.setData('text/plain', draggedItem);
    },
    onDragEnter(event) {
      event.preventDefault();
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDragLeave(event) {
      event.preventDefault();
    },
    onDrop(event) {
      const fieldId = event.dataTransfer.getData('text/plain');
      const listIndex = parseInt(event.target.dataset.listindex, 10);

      if (event.target.dataset.listindex && fieldId) {
        if (!this.uniqueConstraints[listIndex].find(item => item === fieldId)) {
          this.uniqueConstraints[listIndex].push(fieldId);
        }
      }
    },
    removeConstraint(index) {
      this.uniqueConstraints.splice(index, 1);
    },
    removeConstraintItem(listIndex, elementIndex) {
      this.uniqueConstraints[listIndex].splice(elementIndex, 1);
    },
    getFieldNameFromId(fieldId) {
      return this.fields.find(f => f._id === fieldId)?.label;
    },
  },
};
</script>

<style scoped>
.badgeWrapper {
  display: flex;
  flex-wrap: wrap;
}
.badgeWrapper .constraintBadge {
  padding: 3px 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin: 2px;
  user-select: none;
  cursor: pointer;
}
.badgeWrapper .constraintBadge.dragOff {
  cursor: default;
}
.badgeWrapper .constraintBadge.dragOff span {
  cursor: pointer;
}
</style>
