<template>
  <div>
    <a-checkbox :checked="localEvent.changePermissions.activ" @change="changePermissions">
      Override the Owner Role permission on this entry
    </a-checkbox>

    <template v-if="localEvent.changePermissions.activ">
      <div style="margin: 15px">
        <label style="margin-right: 10px">
          <span style="font-weight: 500">Can View</span>
          the Entry
        </label>
        <a-switch :checked="selectedPermission('canView')" @change="updatePermission('canView', $event)" />
      </div>

      <div style="margin: 15px">
        <label style="margin-right: 10px">
          <span style="font-weight: 500">Can Modify</span>
          the Entry
        </label>
        <a-switch :checked="selectedPermission('canModify')" @change="updatePermission('canModify', $event)" />
      </div>
    </template>
  </div>
</template>

<script>
import { WorkflowActions } from '@workflow/shared/workflow.store';

export default {
  name: 'workflowBuilderUpdatePermissionsIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],

  data() {
    return {
      allPermissions: [
        { name: 'Can View', permission: 'canView', value: false },
        { name: 'Can Modify', permission: 'canModify', value: false },
      ],
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {},
  methods: {
    selectedPermission(permission) {
      const findPermission = this.localEvent.changePermissions.permissions.find(p => p.permission === permission);
      if (findPermission) {
        return findPermission.value;
      }
      return false;
    },
    changePermissions(e) {
      this.localEvent.changePermissions.activ = e.target.checked;
      // set default values
      this.localEvent.changePermissions.permissions = this.allPermissions.map(p => {
        return { permission: p.permission, value: p.value };
      });
    },
    updatePermission(permission, value) {
      const findPermission = this.localEvent.changePermissions.permissions.find(p => p.permission === permission);
      if (findPermission) {
        findPermission.value = value;
      } else {
        this.localEvent.changePermissions.permissions.push({
          permission,
          value,
        });
      }
    },
  },
};
</script>
