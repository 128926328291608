<template>
  <div v-if="!noNavbarUrlQuery">
    <ul class="topnav" :style="navbarBackgroundColor">
      <li @click="logoClick" class="logo" style="cursor: pointer" v-if="!tenantSlug">
        <img src="/img/aplay-logo.png" style="width: 120px" />
      </li>
      <li class="logo" v-if="subtenantSlug">
        <!-- {{ subtenantSlug }}  -->
        <img src="/img/aplay-logo.png" style="width: 120px" />
      </li>
      <router-link v-else-if="appSlug" :to="{ name: 'TenantApplication', params: { tenantSlug, appSlug } }" custom v-slot="{ navigate }">
        <li @click="navigate" class="logo" style="cursor: pointer">
          <img src="/img/aplay-logo.png" style="width: 120px" />
        </li>
      </router-link>
      <router-link v-else-if="tenantSlug" :to="{ name: 'Tenant', params: { tenantSlug } }" custom v-slot="{ navigate }">
        <li @click="navigate" class="logo" style="cursor: pointer">
          <img src="/img/aplay-logo.png" style="width: 120px" alt="aPlay" />
        </li>
      </router-link>
      <div class="hide-mobile">
        <template v-if="!tenantSlug && user?.isSuperAdmin">
          <router-link :to="{ name: 'globalBlueprintList' }" custom v-slot="{ href, route, isActive, navigate, isExactActive }">
            <li :class="[isActive && 'active' && $route.name === 'globalBlueprintList', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}{{ tenantSlug }}</a>
            </li>
          </router-link>
        </template>
        <!--    'BlueprintFormBuilder'  fix for preview & test button  reference fields -->
        <template v-if="appUrl && (!subtenantUrl || $route.name === 'BlueprintFormBuilder') && isUserTenantAdmin && $route.name && tenantSlug">
          <router-link
            :to="{
              name: 'BlueprintList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'ApplicationRoleManager',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'WorkflowList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'DataProviderList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'FieldMapperList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'DataAuthRestList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'TemplateManagementList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>

          <router-link
            :to="{
              name: 'MenuBuilderList',
              params: { tenantSlug: tenantSlug, appSlug: appSlug },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
        </template>

        <template v-if="subtenantUrl && $route.name !== 'BlueprintFormBuilder'">
          <router-link
            :to="{
              name: 'templateDataSystemBuilder',
              params: {
                tenantSlug: tenantSlug,
                subtenantSlug: subtenantSlug,
                appSlug: appSlug,
              },
            }"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && 'active', isExactActive && 'exact-active']">
              <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
            </li>
          </router-link>
          <template v-if="isUserSubtenantManager">
            <router-link
              :to="{
                name: 'SubtenantRoleManager',
                params: {
                  tenantSlug: tenantSlug,
                  subtenantSlug: subtenantSlug,
                  appSlug: appSlug,
                },
              }"
              custom
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
              </li>
            </router-link>
          </template>
        </template>
      </div>
      <li class="right mobile-left">
        <AccountComponent :user="user" :appUrl="appUrl" :subtenantUrl="subtenantUrl" />
      </li>
      <li class="right mobile-right" v-if="subtenantUrl">
        <NotificationsComponent :user="user" />
        <div class="inline-mobile">
          <a-dropdown-button>
            <span>Menu</span>
            <template #overlay>
              <a-menu style="width: 200px">
                <template v-if="!tenantSlug && user?.isSuperAdmin">
                  <a-menu-item key="10">
                    <router-link :to="{ name: 'globalBlueprintList' }" custom v-slot="{ href, route, isActive, navigate, isExactActive }">
                      <li :class="[isActive && 'active' && $route.name === 'globalBlueprintList', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}{{ tenantSlug }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                </template>
                <template
                  v-if="appUrl && (!subtenantUrl || $route.name === 'BlueprintFormBuilder') && isUserTenantAdmin && $route.name && tenantSlug"
                >
                  <a-menu-item key="11">
                    <router-link
                      :to="{
                        name: 'BlueprintList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="12">
                    <router-link
                      :to="{
                        name: 'ApplicationRoleManager',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="13">
                    <router-link
                      :to="{
                        name: 'WorkflowList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="14">
                    <router-link
                      :to="{
                        name: 'DataProviderList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="15">
                    <router-link
                      :to="{
                        name: 'FieldMapperList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="16">
                    <router-link
                      :to="{
                        name: 'DataAuthRestList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="17">
                    <router-link
                      :to="{
                        name: 'TemplateManagementList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="18">
                    <router-link
                      :to="{
                        name: 'MenuBuilderList',
                        params: { tenantSlug: tenantSlug, appSlug: appSlug },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                </template>

                <template v-if="subtenantUrl && $route.name !== 'BlueprintFormBuilder'">
                  <a-menu-item key="19">
                    <router-link
                      :to="{
                        name: 'templateDataSystemBuilder',
                        params: {
                          tenantSlug: tenantSlug,
                          subtenantSlug: subtenantSlug,
                          appSlug: appSlug,
                        },
                      }"
                      custom
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                    >
                      <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                        <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                      </li>
                    </router-link>
                  </a-menu-item>
                  <template v-if="isUserSubtenantManager">
                    <a-menu-item key="20">
                      <router-link
                        :to="{
                          name: 'SubtenantRoleManager',
                          params: {
                            tenantSlug: tenantSlug,
                            subtenantSlug: subtenantSlug,
                            appSlug: appSlug,
                          },
                        }"
                        custom
                        v-slot="{ href, route, navigate, isActive, isExactActive }"
                      >
                        <li :class="[isActive && 'active', isExactActive && 'exact-active']">
                          <a :href="href" @click="navigate">{{ route.meta.menuTitle }}</a>
                        </li>
                      </router-link>
                    </a-menu-item>
                  </template>
                </template>
              </a-menu>
            </template>
            <BarsOutlined />
          </a-dropdown-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { BarsOutlined } from '@ant-design/icons-vue';
import { SlugGetters } from '@/slug.store';
import { TenantsGetters, TenantsActions } from '@tenants/shared/tenants.store';
import { UserGetters } from '@userManagement/user.store';
import router from '@/router';
import AccountComponent from './components/Account.vue';
import NotificationsComponent from './components/Notifications.vue';

export default {
  name: 'NavbarComponent',
  created() {
    TenantsActions.fetchTenants();
  },
  computed: {
    user: UserGetters.getUser,
    tenantSlug: SlugGetters.getTenantSlug,
    appSlug: SlugGetters.getAppSlug,
    subtenantSlug: SlugGetters.getSubtenantSlug,
    tenantUrl: SlugGetters.getTenantUrl,
    appUrl: SlugGetters.getAppUrl,
    subtenantUrl: SlugGetters.getSubtenantUrl,
    isUserTenantAdmin() {
      return TenantsGetters.isUserTenantAdmin(this.tenantSlug, this.user._id);
    },
    isUserSubtenantManager() {
      return TenantsGetters.isUserSubtenantManager(this.tenantSlug, this.appSlug, this.subtenantSlug, this.user._id);
    },
    navbarBackgroundColor() {
      if (this.subtenantSlug) {
        return 'background-color: rgba(233, 235, 237, .98)';
      }
      if (this.appSlug) {
        return 'background-color: rgba(231, 237, 239, .98)';
      }
      if (this.tenantSlug) {
        return 'background-color: rgba(226, 235, 243, .98)';
      }
      return 'background-color: rgba(226, 233, 243, .98)';
    },
    noNavbarUrlQuery() {
      return this.$route.query['no-nav'];
    },
  },
  components: {
    NotificationsComponent,
    AccountComponent,
    BarsOutlined,
  },
  methods: {
    logoClick() {
      router
        .push({
          name: 'TenantsList',
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
ul.topnav {
  position: fixed;
  width: 100%;
  z-index: 999;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
}
ul.topnav li {
  padding: 10px 5px;
  float: left;
}
ul.topnav li.logo {
  font-size: 24px;
  font-weight: bolder;
  padding: 5px 30px;
  text-decoration: underline;
}

ul.topnav li a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
}

ul.topnav li:not(.active) a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

ul.topnav li.active a,
ul.topnav li.exact-active a {
  background-color: #1890ff;
  color: #fff;
}

ul.topnav li.right {
  float: right;
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  ul.topnav li.right,
  ul.topnav li {
    float: none;
  }
  .mobile-left {
    float: left !important;
  }
  .mobile-right {
    float: right !important;
  }
}
</style>
