<template>
  <div>
    <div class="row" style="margin-top: 10px; border: 1px">
      <div class="col-2">All pages:</div>
      <div class="col-2">
        <a-checkbox v-model:checked="allAuthorityModeAll" @change="onChangeAllPages($event.target.checked, 'all')"> ALL</a-checkbox>
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="createAuthorityModeAll" @change="onChangeAllPages($event.target.checked, 'createAuthorityMode')">
          Can Create</a-checkbox
        >
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="viewAuthorityModeAll" @change="onChangeAllPages($event.target.checked, 'viewAuthorityMode')">
          Can View</a-checkbox
        >
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="modifyAuthorityModeAll" @change="onChangeAllPages($event.target.checked, 'modifyAuthorityMode')">
          Can Modify</a-checkbox
        >
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="deleteAuthorityModeAll" @change="onChangeAllPages($event.target.checked, 'deleteAuthorityMode')">
          Can Delete</a-checkbox
        >
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-2">Current page:</div>
      <div class="col-2">
        <a-checkbox v-model:checked="pageAuthorityModeAll" @change="onChangeCurentPage($event.target.checked, 'all')"> ALL</a-checkbox>
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="createAuthorityModePage" @change="onChangeCurentPage($event.target.checked, 'createAuthorityMode')">
          Can Create</a-checkbox
        >
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="viewAuthorityModePage" @change="onChangeCurentPage($event.target.checked, 'viewAuthorityMode')">
          Can View</a-checkbox
        >
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="modifyAuthorityModePage" @change="onChangeCurentPage($event.target.checked, 'modifyAuthorityMode')">
          Can Modify</a-checkbox
        >
      </div>
      <div class="col-2">
        <a-checkbox v-model:checked="deleteAuthorityModePage" @change="onChangeCurentPage($event.target.checked, 'deleteAuthorityMode')">
          Can Delete</a-checkbox
        >
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :data-source="blueprintsPermissions"
      :pagination="tablePagination"
      rowKey="blueprintId"
      style="margin-top: 10px"
      @change="onTableChange($event)"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'blueprint'">
          {{ blueprintById[record.blueprintId].name }}
        </template>
        <template v-if="column.key === 'createAuthorityMode'">
          <div>
            <a-checkbox v-model:checked="record.createAuthorityMode" @change="checkCheckoxesCurentPage()"> </a-checkbox>
          </div>
        </template>

        <template v-if="column.key === 'viewAuthorityMode'">
          <a-checkbox v-model:checked="record.viewAuthorityMode" @change="checkCheckoxesCurentPage()"> </a-checkbox>
        </template>

        <template v-if="column.key === 'modifyAuthorityMode'">
          <a-checkbox v-model:checked="record.modifyAuthorityMode" @change="checkCheckoxesCurentPage()"> </a-checkbox>
        </template>

        <template v-if="column.key === 'deleteAuthorityMode'">
          <a-checkbox v-model:checked="record.deleteAuthorityMode" @change="checkCheckoxesCurentPage()"> </a-checkbox>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
const tableColumns = [
  {
    title: 'Business Object',
    key: 'blueprint',
    scopedSlots: { customRender: 'blueprint' },
  },
  {
    title: 'Can Create',
    key: 'createAuthorityMode',
    scopedSlots: { customRender: 'create' },
  },
  {
    title: 'Can View',
    key: 'viewAuthorityMode',
    scopedSlots: { customRender: 'view' },
  },
  {
    title: 'Can Modify',
    key: 'modifyAuthorityMode',
    scopedSlots: { customRender: 'modify' },
  },
  {
    title: 'Can Delete',
    key: 'deleteAuthorityMode',
    scopedSlots: { customRender: 'delete' },
  },
];

export default {
  props: ['blueprintById', 'permissionByBlueprintId'],
  emits: ['update'],
  data() {
    return {
      blueprintsPermissions: this.permissionByBlueprintId,
      currentPage: 1,
      createAuthorityModePage: false,
      viewAuthorityModePage: false,
      modifyAuthorityModePage: false,
      deleteAuthorityModePage: false,
      pageAuthorityModeAll: false,

      createAuthorityModeAll: false,
      viewAuthorityModeAll: false,
      modifyAuthorityModeAll: false,
      deleteAuthorityModeAll: false,
      allAuthorityModeAll: false,
      arrayOfRoles: ['createAuthorityMode', 'viewAuthorityMode', 'modifyAuthorityMode', 'deleteAuthorityMode'],
      tablePagination: {
        pageSize: 15,
      },
      tableColumns,
    };
  },
  mounted() {
    this.blueprintsPermissions = this.permissionByBlueprintId;
    this.checkCheckoxesCurentPage();
  },
  watch: {
    blueprintsPermissions: {
      deep: true,
      handler() {
        this.$emit('update', this.blueprintsPermissions);
      },
    },
  },
  methods: {
    onTableChange(e) {
      if (e?.current) {
        this.currentPage = e.current;
        this.checkCheckoxesCurentPage();
      }
    },
    onChangeAllPages(value, change) {
      if (change !== 'all') {
        this.blueprintsPermissions.forEach(permission => {
          permission[change] = value;
        });
      } else {
        this.blueprintsPermissions.forEach(permission => {
          this.arrayOfRoles.forEach(item => {
            permission[item] = value;
          });
        });
      }

      this.checkCheckoxesCurentPage();
    },
    onChangeCurentPage(value, change) {
      if (change !== 'all') {
        for (
          let i = this.tablePagination.pageSize * (this.currentPage - 1);
          i <
          (this.tablePagination.pageSize * this.currentPage < this.blueprintsPermissions.length
            ? this.tablePagination.pageSize * this.currentPage
            : this.blueprintsPermissions.length);
          i += 1
        ) {
          this.blueprintsPermissions[i][change] = value;
        }
      } else {
        for (
          let i = this.tablePagination.pageSize * (this.currentPage - 1);
          i <
          (this.tablePagination.pageSize * this.currentPage < this.blueprintsPermissions.length
            ? this.tablePagination.pageSize * this.currentPage
            : this.blueprintsPermissions.length);
          i += 1
        ) {
          this.arrayOfRoles.forEach(item => {
            this.blueprintsPermissions[i][item] = value;
          });
        }
      }

      this.checkCheckoxesCurentPage();
    },
    checkCheckoxesCurentPage() {
      let createAuthorityModePage = true;
      let viewAuthorityModePage = true;
      let modifyAuthorityModePage = true;
      let deleteAuthorityModePage = true;

      let createAuthorityModeAll = true;
      let viewAuthorityModeAll = true;
      let modifyAuthorityModeAll = true;
      let deleteAuthorityModeAll = true;

      for (let i = 0; i < this.blueprintsPermissions.length; i += 1) {
        if (this.tablePagination.pageSize * (this.currentPage - 1) <= i && i < this.tablePagination.pageSize * this.currentPage) {
          if (createAuthorityModePage && !this.blueprintsPermissions[i].createAuthorityMode) {
            createAuthorityModePage = false;
          }
          if (viewAuthorityModePage && !this.blueprintsPermissions[i].viewAuthorityMode) {
            viewAuthorityModePage = false;
          }
          if (modifyAuthorityModePage && !this.blueprintsPermissions[i].modifyAuthorityMode) {
            modifyAuthorityModePage = false;
          }
          if (deleteAuthorityModePage && !this.blueprintsPermissions[i].deleteAuthorityMode) {
            deleteAuthorityModePage = false;
          }
        }
        if (createAuthorityModeAll && !this.blueprintsPermissions[i].createAuthorityMode) {
          createAuthorityModeAll = false;
        }
        if (viewAuthorityModeAll && !this.blueprintsPermissions[i].viewAuthorityMode) {
          viewAuthorityModeAll = false;
        }
        if (modifyAuthorityModeAll && !this.blueprintsPermissions[i].modifyAuthorityMode) {
          modifyAuthorityModeAll = false;
        }
        if (deleteAuthorityModeAll && !this.blueprintsPermissions[i].deleteAuthorityMode) {
          deleteAuthorityModeAll = false;
        }
      }
      if (createAuthorityModePage && viewAuthorityModePage && modifyAuthorityModePage && deleteAuthorityModePage) {
        this.pageAuthorityModeAll = true;
      } else {
        this.pageAuthorityModeAll = false;
      }

      this.createAuthorityModePage = createAuthorityModePage;
      this.viewAuthorityModePage = viewAuthorityModePage;
      this.modifyAuthorityModePage = modifyAuthorityModePage;
      this.deleteAuthorityModePage = deleteAuthorityModePage;

      if (createAuthorityModeAll && viewAuthorityModeAll && modifyAuthorityModeAll && deleteAuthorityModeAll) {
        this.allAuthorityModeAll = true;
      } else {
        this.allAuthorityModeAll = false;
      }

      this.createAuthorityModeAll = createAuthorityModeAll;
      this.viewAuthorityModeAll = viewAuthorityModeAll;
      this.modifyAuthorityModeAll = modifyAuthorityModeAll;
      this.deleteAuthorityModeAll = deleteAuthorityModeAll;
    },
  },
};
</script>

<style></style>
