import axios from 'axios';

import { UserActions } from './user.store';

// this Interceptor tries to silently log the User if any axios request responded with Unauthorized status
export const authRequestInterceptor = [
  response => {
    return response;
  },
  async error => {
    if (error.response.status !== 401 || error.config.url === '/auth/login-refresh') {
      return Promise.reject(error);
    }

    const requestRetryConfig = {
      ...error.config,
    };

    try {
      const { accessToken } = await UserActions.silentLogin();
      requestRetryConfig.headers.Authorization = `Bearer ${accessToken}`;
    } catch {
      window.location.href = '/';
      return Promise.reject(error);
    }

    return axios.request(requestRetryConfig);
  },
];
