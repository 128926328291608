import TemplateLayoutBuilder from '@/apps/templateDataSystem/views/Builder/TemplateLayoutBuilder.vue';
import PublicTemplateDataSystemBuilder from '@templateDataSystem/views/Public/PublicTemplateDataSystemBuilder.vue';

export const templateDataSystemRoutes = [
  {
    path: '/:menuItemId?/:templateId?/:viewType?/:instanceId?',
    name: 'templateDataSystemBuilder',
    component: TemplateLayoutBuilder,
    meta: {
      menuTitle: 'Collections',
    },
  },

  {
    path: '/:menuItemId?/:templateId?/:viewType?/:instanceId?/:oldTemplateId?',
    name: 'templateDataSystemListOpenTemplate',
    component: TemplateLayoutBuilder,
    meta: {
      menuTitle: 'Collections',
    },
  },
];
export const publicTemplateDataSystemRoutes = [
  {
    path: '/:subtenantSlug/:templateId?',
    name: 'BlueprintPublicForm',
    component: PublicTemplateDataSystemBuilder,
    props: true,
    meta: {
      isPublicPage: true,
    },
  },
];
