<template>
  <div :style="!openInModal ? 'padding-top: 20px' : ''">
    <div v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <div>
        <template v-if="!openInModal">
          <router-link :to="{ name: 'DataAuthRestList' }" custom v-slot="{ navigate }">
            <a-button size="small" @click="navigate"><CaretLeftOutlined /> Back to list</a-button>
          </router-link>

          <a-divider></a-divider>
        </template>

        <div class="mb-3">
          <a-input v-model:value="dataAuthRest.name" placeholder="Name" />
        </div>
        <div class="mb-3">
          <a-input v-model:value="dataAuthRest.description" placeholder="Description" />
        </div>

        <div class="card mb-3">
          <div class="card-body">
            <h5 class="card-title">Authorization availability</h5>

            <a-radio-group v-model:value="dataAuthRest.isGlobal" :defaultValue="false">
              <a-radio :value="false"> Only for parent APP </a-radio>
              <a-radio :value="true"> All APPS </a-radio>
            </a-radio-group>
          </div>
        </div>

        <template v-if="dataAuthRest">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Request</h5>

              {{ additionalDataRequest }}

              <SubscriptionForm :additional-data-request="additionalDataRequest" v-model="dataAuthRest.httpSubscription" />

              <div class="mt-3">
                <a-button :loading="saveLoading" type="primary" @click="save">SAVE</a-button>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <h5 class="card-title">Response</h5>

              <a-button type="primary" size="small" @click="openModalAdditionalManuallyData('response')">Add data manually</a-button>
              <a-spin :spinning="testResponseLoading">
                <div class="jsonPretty" v-if="dataAuthRest.response">
                  <pre v-html="jsonPretty(dataAuthRest.response)"></pre>
                </div>

                <div class="mt-3">
                  <a-button type="primary" :loading="testResponseLoading" @click="testEndpoint"
                    >{{ dataAuthRest.response ? 'RETEST' : 'TEST' }} RESPONSE</a-button
                  >
                </div>
              </a-spin>

              <a-divider></a-divider>

              <div class="mt-3">
                <template v-if="dataAuthRest.responseDataAuthRestId">
                  <div class="mb-2">Trigger this</div>
                  <div class="card mb-10 pointer">
                    <div @click="openPopup(dataAuthRest.responseDataAuthRestId, 'request')" class="card-body">
                      {{ dataAuthRest.responseDataAuthRestId.name }}
                    </div>
                    <a-popconfirm
                      @confirm="deleteDataAuthRest(dataAuthRest.responseDataAuthRestId._id, 'request')"
                      title="Are you sure you what to delete？"
                      ok-text="Yes"
                      cancel-text="No"
                    >
                      <div class="delete-button">
                        <a-button :loading="deleteAuthRestDataLoading === 'request'" type="primary" danger><DeleteOutlined /></a-button>
                      </div>
                    </a-popconfirm>
                  </div>
                </template>
                <div v-else>
                  <a-button @click="addOtherRequest('return')" :loading="addOtherRequestLoading === 'return'">ADD ANOTHER REQUEST</a-button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <h5 class="card-title">Generate Return URL</h5>

              <div class="mt-3">
                <a-input addon-before="Return URL:" v-model:value="baseUrl" />
              </div>

              <div class="mt-3">
                <h5 class="card-title">Return stored data</h5>

                <a-button type="primary" size="small" @click="openModalAdditionalManuallyData('returnResponse')">Add data manually</a-button>

                <a-spin :spinning="reloadReturnResponseLoading">
                  <div class="jsonPretty" v-if="dataAuthRest.returnResponse">
                    <pre v-html="jsonPretty(dataAuthRest.returnResponse)"></pre>
                  </div>
                </a-spin>
              </div>

              <div class="mt-3">
                <a-button type="primary" :loading="reloadReturnResponseLoading" @click="reloadReturnResponse"
                  >{{ dataAuthRest.response ? 'RELOAD' : 'LOAD' }} RESPONSE</a-button
                >
              </div>

              <a-divider></a-divider>

              <div class="mt-3">
                <template v-if="dataAuthRest.returnResponseDataAuthRestId">
                  <div class="mb-2">Trigger this</div>
                  <div class="card mb-10 pointer">
                    <div @click="openPopup(dataAuthRest.returnResponseDataAuthRestId, 'returnResponse')" class="card-body">
                      {{ dataAuthRest.returnResponseDataAuthRestId.name }}
                    </div>
                    <a-popconfirm
                      @confirm="deleteDataAuthRest(dataAuthRest.returnResponseDataAuthRestId._id, 'returnResponse')"
                      title="Are you sure you what to delete？"
                      ok-text="Yes"
                      cancel-text="No"
                    >
                      <div class="delete-button">
                        <a-button :loading="deleteAuthRestDataLoading === 'returnResponse'" type="primary" danger><DeleteOutlined /></a-button>
                      </div>
                    </a-popconfirm>
                  </div>
                </template>
                <div v-else>
                  <a-button @click="addOtherRequest('returnResponse')" :loading="addOtherRequestLoading === 'returnResponse'"
                    >ADD ANOTHER REQUEST</a-button
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <a-modal v-model:open="openModalAuthRestDataAction" :destroyOnClose="true" width="90%" @cancel="closePopup" @ok="closePopup" title="Basic Modal">
      <DataAuthRestItem
        :props-auth-rest-data-id="openModalAuthRestData?._id"
        :additional-data-request="openModalAuthRestDataRequest"
        :openInModal="true"
      />
    </a-modal>

    <a-modal v-model:open="modalAdditionalManuallyDataAction" width="800px" @cancel="closeModalAdditionalManuallyData" title="Add data manually">
      <div class="row mb-3" v-for="(body, index) in modalAdditionalManuallyData" :key="'body_' + index">
        <div class="col-4">
          <a-input v-model:value="body.key" placeholder="Key" />
        </div>
        <div class="col-7">
          <a-input v-model:value="body.value" placeholder="Value" />
        </div>
        <div class="col-1">
          <a-button @click="removeAdditionalManuallyData(body)" v-if="index > 0"><DeleteOutlined /></a-button>
        </div>
      </div>

      <a-button @click="addExtraAdditionalManuallyData()"><PlusOutlined /> Add more</a-button>

      <template #footer>
        <a-button type="primary" :loading="modalAdditionalManuallyDataLoading" @click="addAdditionalManuallyData"> ADD DATA </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { DataAuthRestActions } from '@dataAuthRest/shared/dataAuthRest.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { dataAuthRest } from '@dataAuthRest/api';
import { DeleteOutlined, CaretLeftOutlined, PlusOutlined } from '@ant-design/icons-vue';
import SubscriptionForm from './components/subscription.vue';
// import { Builder } from './Builder.vue';

export default {
  name: 'DataAuthRestItem',
  props: ['propsAuthRestData', 'propsAuthRestDataId', 'additionalDataRequest', 'openInModal'],
  data() {
    return {
      authRestId: this.$route.params.authRestId,
      loading: true,
      saveLoading: false,
      testResponseLoading: false,
      reloadReturnResponseLoading: false,

      dataAuthRest: null,

      openModalAuthRestDataAction: false,
      openModalAuthRestDataRequest: null,
      openModalAuthRestData: null,

      modalAdditionalManuallyDataLoading: false,
      modalAdditionalManuallyData: [],
      modalAdditionalManuallyDataAction: false,
      modalAdditionalManuallyDataType: null,

      addOtherRequestLoading: null,

      deleteAuthRestDataLoading: null,
    };
  },
  watch: {
    '$route.params.authRestId': async function (value) {
      this.authRestId = value;
      await this.fetchOne();
    },
  },
  async mounted() {
    if (this.propsAuthRestData) {
      this.loading = false;
      this.dataAuthRest = this.propsAuthRestData;
      this.authRestId = this.propsAuthRestData._id;
    }
    if (this.propsAuthRestDataId) {
      this.authRestId = this.propsAuthRestDataId;
    }
    await this.fetchOne();
  },
  computed: {
    baseUrl() {
      const { tenant, app } = TenantsGetters.getTenantAppSubtenant();
      return `${process.env.VUE_APP_BASE_URL}/public/${tenant?.slug}/${app?.slug}/data-auth-rest/endpoint/${this.dataAuthRest._id}/${this.dataAuthRest.secret}`;
    },
  },

  methods: {
    async deleteDataAuthRest(dataAuthRestId, type) {
      this.deleteAuthRestDataLoading = type;
      await dataAuthRest.deleteOne(dataAuthRestId);
      await this.fetchOne(false);
      this.deleteAuthRestDataLoading = null;
    },
    openModalAdditionalManuallyData(type) {
      this.modalAdditionalManuallyDataAction = true;
      this.modalAdditionalManuallyDataType = type;
      this.modalAdditionalManuallyData.push({
        key: undefined,
        value: undefined,
      });
    },
    closeModalAdditionalManuallyData() {
      this.modalAdditionalManuallyDataAction = false;
      this.modalAdditionalManuallyDataType = null;
      this.modalAdditionalManuallyData = [];
    },
    addExtraAdditionalManuallyData() {
      this.modalAdditionalManuallyData.push({
        key: undefined,
        value: undefined,
      });
    },
    async addAdditionalManuallyData() {
      let form = {
        ...this.dataAuthRest,
      };
      if (!form[this.modalAdditionalManuallyDataType]?.data) {
        form = {
          ...form,
          ...{ [this.modalAdditionalManuallyDataType]: { data: {} } },
        };
      }
      form[this.modalAdditionalManuallyDataType].data = {
        ...(form[this.modalAdditionalManuallyDataType].data ?? {}),
        ...this.modalAdditionalManuallyData.reduce((acc, obj) => {
          acc[obj.key] = obj.value;
          return acc;
        }, {}),
      };

      this.modalAdditionalManuallyDataLoading = true;
      await DataAuthRestActions.editOne(this.authRestId, form);
      this.closeModalAdditionalManuallyData();
      this.modalAdditionalManuallyDataLoading = false;
    },
    removeAdditionalManuallyData(row) {
      const key = this.modalAdditionalManuallyData.indexOf(row);
      this.modalAdditionalManuallyData = this.modalAdditionalManuallyData.filter(r => this.modalAdditionalManuallyData.indexOf(r) !== key);
    },

    openPopup(authRestData, type) {
      this.openModalAuthRestDataAction = true;
      this.openModalAuthRestData = authRestData;
      this.openModalAuthRestDataRequest =
        type === 'request' ? (this.dataAuthRest?.response?.data ?? {}) : (this.dataAuthRest?.returnResponse?.data ?? {});
    },
    closePopup() {
      this.openModalAuthRestDataAction = false;
      this.openModalAuthRestDataRequest = null;
      this.openModalAuthRestData = null;
    },
    async fetchOne(loading = true) {
      if (!this.propsAuthRestData) {
        this.loading = loading;
        this.dataAuthRest = await dataAuthRest.getOne(this.authRestId);
        this.loading = false;
      }
    },
    async reloadReturnResponse() {
      this.reloadReturnResponseLoading = true;
      await this.fetchOne(false);
      this.reloadReturnResponseLoading = false;
    },
    async testEndpoint() {
      this.testResponseLoading = true;
      await this.save();

      if (this.dataAuthRest.httpSubscription?.openInTab) {
        if (this.dataAuthRest.httpSubscription?.url !== '') {
          const query =
            (this.dataAuthRest.httpSubscription.query.filter(item => item.key).length ? '?' : '') +
            this.dataAuthRest.httpSubscription.query
              .filter(item => item.key)
              .map(
                obj =>
                  `${encodeURIComponent(obj.key)}=${encodeURIComponent(obj.value ?? '')}${obj?.keyValue ? `${encodeURIComponent(this.additionalDataRequest[obj?.keyValue] ?? '')}` : ''}`
              )
              .join('&');

          window.open(this.dataAuthRest.httpSubscription.url + query, '_blank');
        }
      } else {
        this.dataAuthRest = await DataAuthRestActions.test(this.authRestId, {
          additionalDataRequest: this.additionalDataRequest,
        });
      }

      this.testResponseLoading = false;
    },
    async addOtherRequest(type) {
      this.addOtherRequestLoading = type;
      const request = await DataAuthRestActions.addNew(`New ${type === 'returnResponse' ? 'return' : type} auth rest`, this.authRestId, false);
      const form = {
        ...this.dataAuthRest,
        [type === 'return' ? 'responseDataAuthRestId' : 'returnResponseDataAuthRestId']: request._id,
      };
      this.dataAuthRest = await DataAuthRestActions.editOne(this.authRestId, form);
      this.addOtherRequestLoading = null;
    },
    async save() {
      this.saveLoading = true;
      const form = {
        ...this.dataAuthRest,
      };
      await DataAuthRestActions.editOne(this.authRestId, form);
      this.saveLoading = false;
    },

    jsonPretty(json) {
      const options = {};
      const defaults = { indent: 3, linkUrls: true, quoteKeys: false };
      const settings = { ...defaults, ...options };
      const htmlEntities = text => {
        return text.replace(/&/g, '&amp;').replace(/\\"/g, '&bsol;&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      };
      const buildValueHtml = value => {
        const strType = /^"/.test(value) && 'string';
        const boolType = ['true', 'false'].includes(value) && 'boolean';
        const nullType = value === 'null' && 'null';
        const type = boolType || nullType || strType || 'number';
        const urlRegex = /https?:\/\/[^\s"]+/g;
        const makeLink = link => `<a class="json-link" href="${link}">${link}</a>`;
        const display = strType && settings.linkUrls ? value.replace(urlRegex, makeLink) : value;
        return `<span class=json-${type}>${display}</span>`;
      };
      const replacer = (match, p1, p2, p3, p4) => {
        const part = { indent: p1, key: p2, value: p3, end: p4 };
        const findName = settings.quoteKeys ? /(.*)(): / : /"([\w$]+)": |(.*): /;
        const indentHtml = part.indent || '';
        const keyName = part.key && part.key.replace(findName, '$1$2');
        const keyHtml = part.key ? `<span class=json-key>${keyName}</span>: ` : '';
        const valueHtml = part.value ? buildValueHtml(part.value) : '';
        const endHtml = part.end || '';
        return indentHtml + keyHtml + valueHtml + endHtml;
      };
      const jsonLine = /^( *)("[^"]+": )?("[^"]*"|[\w.+-]*)?([{}[\],]*)?$/gm;
      const newJson = JSON.stringify(json, null, settings.indent);

      return htmlEntities(newJson).replace(jsonLine, replacer);
    },
  },
  components: {
    // eslint-disable-next-line import/no-self-import
    DataAuthRestItem: () => import('./Builder.vue'),
    SubscriptionForm,
    DeleteOutlined,
    CaretLeftOutlined,
    PlusOutlined,
  },
};
</script>
<style scoped>
.delete-button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
.card-body {
  padding: 10px;
  margin-right: 40px;
}
</style>
