<template>
  <a-row style="margin: 50px" type="flex">
    <EmailChange v-bind="emailChangeProps" @close="onEmailChangeDrawerClose" />
    <PasswordChange v-bind="passwordChangeProps" @close="onPasswordChangeDrawerClose" />
    <ProfileEdit v-bind="profileEditProps" @close="onProfileEditDrawerClose" />
    <a-col class="mobile-account-cols" flex="500px">
      <a-card>
        <a-avatar shape="square" size="large"><UserOutlined /></a-avatar>
        <span style="font-weight: 500; margin-left: 15px">
          {{ user.email }}
        </span>
      </a-card>
      <a-card>
        <div class="edit-profile-div" style="position: absolute; display: block; right: 20px; top: 20px">
          <a-button @click="openProfileEditDrawer" type="primary" ghost> <EditOutlined /> Edit profile info </a-button>
        </div>
        <a-descriptions title="Profile information" :column="1">
          <a-descriptions-item label="First name">
            <span style="font-weight: 500">{{ user.lastName }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="Last name">
            <span style="font-weight: 500">{{ user.firstName }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="Country">
            <span v-if="!user.profile.country" style="color: rgba(0, 0, 0, 0.5)"> empty </span>
            <span v-else style="font-weight: 500">
              {{ user.profile.country }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="City">
            <span v-if="!user.profile.city" style="color: rgba(0, 0, 0, 0.5)"> empty </span>
            <span v-else style="font-weight: 500">
              {{ user.profile.city }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Address">
            <span v-if="!user.profile.address" style="color: rgba(0, 0, 0, 0.5)"> empty </span>
            <span v-else style="font-weight: 500">
              {{ user.profile.address }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="LinkedIn">
            <span v-if="!user.profile.linkedin" style="color: rgba(0, 0, 0, 0.5)"> empty </span>
            <span v-else style="font-weight: 500">
              {{ user.profile.linkedin }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Facebook">
            <span v-if="!user.profile.facebook" style="color: rgba(0, 0, 0, 0.5)"> empty </span>
            <span v-else style="font-weight: 500">
              {{ user.profile.facebook }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <div class="show-mobile">
          <a class="ant-btn ant-btn-primary ant-btn-background-ghost" @click.prevent="openPasswordChangeDrawer"><LockOutlined /> Change password</a>
        </div>
      </a-card>
    </a-col>
    <a-col class="hide-mobile" flex="auto">
      <div style="padding: 25px">
        <!-- <p>
          <a @click.prevent="openEmailChangeDrawer">Change e-mail address</a>
        </p> -->
        <p>
          <a class="ant-btn ant-btn-primary ant-btn-background-ghost" @click.prevent="openPasswordChangeDrawer"><LockOutlined /> Change password</a>
        </p>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { EditOutlined, LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import { UserGetters } from '@userManagement/user.store';
import EmailChange from './components/EmailChange/EmailChange.vue';
import PasswordChange from './components/PasswordChange/PasswordChange.vue';
import ProfileEdit from './components/ProfileEdit/ProfileEdit.vue';

export default {
  name: 'userManagementAccount',
  components: {
    EmailChange,
    PasswordChange,
    ProfileEdit,
    LockOutlined,
    EditOutlined,
    UserOutlined,
  },
  data() {
    return {
      emailChangeProps: {
        visible: false,
      },
      passwordChangeProps: {
        visible: false,
      },
      profileEditProps: {
        visible: false,
      },
    };
  },
  computed: {
    user() {
      return UserGetters.getUser();
    },
  },
  methods: {
    openEmailChangeDrawer() {
      this.emailChangeProps = {
        visible: true,
      };
    },
    openPasswordChangeDrawer() {
      this.passwordChangeProps = {
        visible: true,
      };
    },
    openProfileEditDrawer() {
      this.profileEditProps = {
        visible: true,
      };
    },
    onEmailChangeDrawerClose() {
      this.emailChangeProps = {
        visible: false,
      };
    },
    onPasswordChangeDrawerClose() {
      this.passwordChangeProps = {
        visible: false,
      };
    },
    onProfileEditDrawerClose() {
      this.profileEditProps = {
        visible: false,
      };
    },
  },
};
</script>

<style>
.show-mobile {
  display: none;
}
.hide-mobile {
  display: block;
}
.inline-mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  .mobile-account-cols {
    flex: auto !important;
    width: 100%;
    display: block;
  }
  .edit-profile-div {
    position: relative !important;
    right: 0px !important;
    top: 0px !important;
    margin-bottom: 10px;
  }
  .show-mobile {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .inline-mobile {
    display: inline;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .ant-row-flex {
    margin: 0 !important;
  }
}
</style>
