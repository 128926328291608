<template>
  <a-drawer :title="`Invite ${rankLabel}(s)`" width="700px" :open="visible" @close="$emit('close')">
    <h4>New invitations</h4>
    <label>
      Enter e-mail address to invite as
      <span style="font-weight: 700">{{ rankLabel }}</span>
    </label>
    <a-input @pressEnter="addRecipient" v-model:value="recipientInput" style="width: 500px" />
    <a-button @click="addRecipient" type="primary" ghost style="margin-left: 15px"> Add recipient </a-button>
    <div v-if="recipientInputError">
      <p style="font-weight: 500; color: #a8071a; margin-left: 5px; margin-top: 5px">
        {{ recipientInputError }}
      </p>
    </div>
    <div v-if="recipients.length > 0" style="margin-top: 20px">
      <label>Recipients:</label>
      <ul>
        <li v-for="recipient in recipients" :key="recipient">
          <span>{{ recipient }}</span>
          <a-button size="small" style="margin-left: 10px" @click="() => removeRecipient(recipient)"><DeleteOutlined /> </a-button>
        </li>
      </ul>
      <a-button @click="sendInvitations" type="primary" style="display: inline-block"> Send {{ recipients.length }} invitation(s) </a-button>
      <a-button @click="clearRecipients" style="display: inline-block; margin-left: 15px"> Clear recipients </a-button>
    </div>

    <a-divider />

    <h4>Sent invitations</h4>
    <a-table :columns="tableColumns" :data-source="tableData" rowKey="_id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <div>
            <a-button @click="() => record._id" size="small"> Cancel </a-button>
          </div>
        </template>
        <template v-if="column.key == 'expiryDate'">
          {{ relativeTimeFromNow(record.expiryDate) }}
        </template>
      </template>
    </a-table>
  </a-drawer>
</template>

<script>
import { invitationApi } from '@userManagement/api';
import { UserGetters } from '@userManagement/user.store';
import { DeleteOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { message } from 'ant-design-vue';

const tableColumns = [
  {
    title: 'E-mail',
    key: 'recipientEmail',
    dataIndex: 'recipientEmail',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Expires in',
    key: 'expiryDate',
    dataIndex: 'expiryDate',
  },
  {
    title: 'Actions',
    key: 'actions',
    dataIndex: 'actions',
  },
];

export default {
  props: ['visible', 'tenantId', 'appId', 'subtenantId', 'rank'],
  emits: ['close'],
  components: { DeleteOutlined },
  data() {
    return {
      invitations: [],
      tableColumns,
      recipients: [],
      recipientInput: '',
      recipientInputError: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    tableData() {
      const formattedInvitations = this.invitations.map(invitation => {
        let status = 'Pending';
        if (invitation.isAccepted) {
          status = 'Accepted';
        }
        if (invitation.isRevoked) {
          status = 'Revoked';
        }
        const currentDate = new Date();
        if (invitation.expiryDate > currentDate) {
          status = 'Expired';
        }

        return {
          ...invitation,
          status,
          expiryDate: status !== 'Pending' ? null : invitation.expiryDate,
        };
      });
      return formattedInvitations.sort((a, b) => {
        return new Date(b.expiryDate) - new Date(a.expiryDate);
      });
    },
    user() {
      return UserGetters.getUser();
    },
    rankLabel() {
      switch (this.rank) {
        case 'tenant-admin': {
          return 'Admin';
        }
        case 'subtenant-manager': {
          return 'Manager';
        }
        case 'subtenant-user': {
          return 'User';
        }
        default: {
          return '';
        }
      }
    },
  },
  watch: {
    rank() {
      this.init();
    },
    visible() {
      this.init();
    },
    recipientInput() {
      this.recipientInputError = null;
    },
  },
  methods: {
    relativeTimeFromNow(date) {
      if (!date) {
        return null;
      }
      dayjs.extend(relativeTime);
      return dayjs(date).fromNow();
    },
    init() {
      this.fetchInvitations();
    },
    async fetchInvitations() {
      const invitations = await invitationApi.getInvitations({
        type: this.rank,
        tenantId: this.tenantId,
        subtenantId: this.subtenantId,
      });
      this.invitations = invitations;
    },
    async sendInvitations() {
      let meta = {};

      switch (this.rank) {
        case 'tenant-admin': {
          meta = {
            type: this.rank,
            tenantId: this.tenantId,
          };
          break;
        }
        case 'subtenant-manager': {
          meta = {
            type: this.rank,
            tenantId: this.tenantId,
            subtenantId: this.subtenantId,
          };
          break;
        }
        case 'subtenant-user':
        default: {
          meta = {
            type: this.rank,
            tenantId: this.tenantId,
            subtenantId: this.subtenantId,
          };
          break;
        }
      }

      const newInvitations = await invitationApi.postInvitation({
        senderUserId: this.user._id,
        recipientEmailList: this.recipients,
        meta,
      });

      this.invitations.push(...newInvitations);

      message.success('Invitations sent.');

      this.recipients = [];
      this.recipientInput = '';
    },
    async revokeInvitation(invitationId) {
      await invitationApi.deleteInvitation(invitationId);
      this.invitations =
        this.invitations.forEach((invitation, index) => {
          if (invitation._id !== invitationId) {
            invitation.splice(index, 1);
          }
        }) ?? [];
    },
    validateEmail(email) {
      return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    },
    addRecipient() {
      if (!this.recipientInput || this.recipientInput.trim() === '') {
        return;
      }
      if (!this.validateEmail(this.recipientInput)) {
        this.recipientInputError = 'Invalid e-mail address.';
        return;
      }
      const formattedEmail = this.recipientInput.toLowerCase().trim();

      if (this.recipients.indexOf(formattedEmail) !== -1) {
        this.recipientInputError = 'Recipient already added!';
        return;
      }

      this.tableData.some(invitation => {
        if (invitation.recipientEmail.toLowerCase().trim() === formattedEmail) {
          if (invitation.status === 'Pending') {
            this.recipientInputError = 'This e-mail has a Pending invitation.';
          }
          return true;
        }
        return false;
      });

      this.recipientInputError = null;

      if (formattedEmail && formattedEmail.length > 0 && this.recipients.indexOf(formattedEmail) === -1) {
        this.recipients.push(formattedEmail);
      }
      this.recipientInput = '';
    },
    removeRecipient(emailToRemove) {
      this.recipients = this.recipients.filter(recipient => recipient !== emailToRemove);
    },
    clearRecipients() {
      this.recipients = [];
    },
  },
};
</script>

<style></style>
