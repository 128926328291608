<template>
  <a-modal
    :title="editedBlueprint ? `Edit Business Object: ${editedBlueprint.name}` : 'Create a new Business Object'"
    :open="visible"
    @cancel="cancel"
    :destroyOnClose="true"
  >
    <template #footer>
      <a-button @click="onClickCancel">Cancel</a-button>
      <a-button type="primary" @click="onClickSave">{{ editedBlueprint ? 'Save' : 'Create' }}</a-button>
    </template>
    <div style="margin: 10px 0px">
      <label>Name</label>
      <a-input v-on:keyup.enter="onKeyUpEnter" autoFocus placeholder="Enter the name" v-model:value="input.name" />
    </div>

    <BlueprintSelectTenantsApps :input="input" :tenantsAndApps="tenantsAndApps" @updateInput="updateInput" />

    <div style="margin: 10px 0px">
      <label>Description</label>
      <a-textarea placeholder="Optional description" v-model:value="input.description" :rows="5" />
    </div>
  </a-modal>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import BlueprintSelectTenantsApps from '@dataSystem/views/BlueprintList/components/BluprintSelectTenantsApps';

export default {
  name: 'BlueprintCreateModal',
  data() {
    return {
      input: {
        name: '',
        description: '',
        isGlobal: this.editedBlueprint?.isGlobal ?? false,
      },
    };
  },
  components: {
    BlueprintSelectTenantsApps,
  },
  props: ['visible', 'tenantsAndApps', 'editedBlueprint'],
  emits: ['created', 'cancel'],
  watch: {
    visible() {
      if (this.visible === false) {
        this.input = {
          name: '',
          description: '',
          isGlobal: false,
        };
      }
    },
    editedBlueprint() {
      if (this.editedBlueprint) {
        this.input.name = this.editedBlueprint.name;
        this.input.description = this.editedBlueprint.description;
        this.input.isGlobal = this.editedBlueprint?.isGlobal ?? false;
        this.input.applicationIdList = this.editedBlueprint?.applicationIdList ?? [];
      }
    },
  },
  methods: {
    updateInput(data) {
      this.input = data;
    },
    async createBlueprint() {
      if (!this.input.name || this.input.name.length === 0) {
        return;
      }
      await blueprintApi.postOne(this.input);
      this.$emit('created');
    },
    async editBlueprint() {
      if (!this.input.name || this.input.name.length === 0) {
        return;
      }
      await blueprintApi.patchOne(this.editedBlueprint._id, this.input);
      this.$emit('created');
    },
    cancel() {
      this.$emit('cancel');
    },
    onKeyUpEnter() {
      this.createBlueprint();
    },
    onClickSave() {
      if (this.editedBlueprint) {
        this.editBlueprint();
      } else {
        this.createBlueprint();
      }
    },
    onClickCancel() {
      this.cancel();
    },
  },
};
</script>

<style></style>
