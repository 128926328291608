<template>
  <a-drawer width="700px" :open="visible" @close="$emit('close')">
    <h2>Link sharing</h2>
    <div style="margin-top: 15px">
      <div style="border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 5px; padding: 15px; dispaly: inline-block; margin: 10px; margin-left: 0px">
        <a :href="linkSharingUrl" target="_blank">
          {{ linkSharingUrl }}
        </a>
      </div>
      <p style="font-size: 0.85rem; margin-left: 5px; margin-top: 5px">Anyone with the link will be able to create new entries.</p>
      <h4>Share link with people</h4>
      <a-input
        v-model:value="recipientInput"
        placeholder="Enter e-mail address"
        style="display: inline-block; max-width: 500px"
        type="email"
        @pressEnter="addRecipient"
      />
      <a-button @click="addRecipient" type="primary" ghost style="display: inline-block; margin-left: 5px"> Add recipient </a-button>

      <div v-if="recipientInputError">
        <p style="font-weight: 500; color: #a8071a; margin-left: 5px; margin-top: 5px">
          {{ recipientInputError }}
        </p>
      </div>

      <ul style="margin-top: 10px">
        <li v-for="recipient in recipients" :key="recipient">
          {{ recipient }}
        </li>
      </ul>

      <template v-if="recipients.length > 0">
        <div style="margin-top: 10px">
          <a-button @click="onClickSend" type="primary"> Send link to {{ recipients.length }} recipient(s) </a-button>
          <a-button @click="clearRecipients" style="dispaly: inline-block; margin-left: 15px"> Clear recipients </a-button>
        </div>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';

import { SlugGetters } from '@/slug.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { message } from 'ant-design-vue';

export default {
  name: 'BlueprintFormLinkSharing',
  props: ['blueprintId', 'visible'],
  data() {
    return {
      blueprint: null,
      recipients: [],
      recipientInput: '',
      recipientInputError: null,
      randomString: Math.random().toString(36).substring(7),
    };
  },
  created() {
    this.fetchBlueprint();
  },
  watch: {
    blueprintId() {
      this.fetchBlueprint();
    },
    recipientInput() {
      this.recipientInputError = null;
    },
  },
  computed: {
    linkSharingUrl() {
      if (!this.blueprint) {
        return null;
      }
      const subtenant = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
      return `${process.env.VUE_APP_FRONTEND_URL}/public/form/${subtenant._id}/${this.blueprint.linkSharing.slug}`;
    },
  },
  methods: {
    async fetchBlueprint() {
      const { blueprint } = await blueprintApi.getOne(this.blueprintId);
      this.blueprint = blueprint;
    },
    async onClickSend() {
      await blueprintApi.postLinkSharingMail(this.blueprintId, this.recipients);
      message.success('Successfully sent!');
      this.clearRecipients();
    },
    addRecipient() {
      if (!this.recipientInput || this.recipientInput.trim() === '') {
        return;
      }
      if (!this.recipientInput.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
        this.recipientInputError = 'Invalid e-mail address.';
        return;
      }
      const formattedEmail = this.recipientInput.toLowerCase().trim();

      if (this.recipients.indexOf(formattedEmail) !== -1) {
        this.recipientInputError = 'Recipient already added!';
        return;
      }

      if (formattedEmail && formattedEmail.length > 0 && this.recipients.indexOf(formattedEmail) === -1) {
        this.recipients.push(formattedEmail);
      }
      this.recipientInput = '';
    },
    clearRecipients() {
      this.recipients = [];
    },
  },
};
</script>
