import { createRouter, createWebHistory } from 'vue-router';

import { authGuard } from '@userManagement/auth.guard';

import { SlugMutations } from './slug.store';

import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  SlugMutations.UPDATE_SLUG_ON_ROUTE_CHANGE(to);
  next();
});

router.beforeEach(authGuard);

export default router;
