<template>
  <div class="appFooter">
    <SendUserFeedback v-if="user" />

    <div class="appVersion">
      {{ appVersion }}
    </div>
  </div>
</template>

<script>
import { UserGetters } from '@userManagement/user.store';
import SendUserFeedback from '@userManagement/components/SendUserFeedback.vue';

export default {
  name: 'FooterComponent',
  components: { SendUserFeedback },
  computed: {
    user: UserGetters.getUser,
    appVersion() {
      return this.__appVersion;
    },
  },
};
</script>

<style scoped></style>
