<template>
  <a-collapse v-model:activeKey="extendedPanels">
    <a-collapse-panel key="1" header="Trimite SMS">
      <a-row>
        <a-col span="24" class="mb-3">
          <a-select
            mode="tags"
            v-model:value="phones"
            style="width: 100%"
            placeholder="Telefoane"
            :options="phoneOptions"
            @change="handleChange"
          ></a-select>
        </a-col>

        <a-col span="24" class="mb-3">
          <a-textarea placeholder="Mesaj de trimis" v-model:value="message"></a-textarea>
          <span style="text-align: right; display: block">Numar caractere: {{ characterCount }}</span>
        </a-col>

        <a-col span="24">
          <a-button type="primary" @click="sendSMS" :loading="loading">Trimite SMS</a-button>
        </a-col>
      </a-row>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { notification } from 'ant-design-vue';
import { instanceApi } from '@dataSystem/api';

export default {
  name: 'SendSMS',
  data() {
    return {
      phones: [],
      message: '',
      loading: false,
      extendedPanels: [],
    };
  },
  props: ['phonesToSend'],
  computed: {
    phoneOptions() {
      const options = [];
      this.phonesToSend.forEach(phone => {
        if (phone) {
          options.push({
            value: phone,
            label: phone,
          });
        }
      });
      return options;
    },
    characterCount() {
      return this.message.length;
    },
  },
  watch: {
    phonesToSend() {},
  },
  methods: {
    handleChange() {
      // console.log(`selected ${value}`);
    },
    async sendSMS() {
      this.loading = true;

      if (this.message.length > 4) {
        if (this.phones.length) {
          const smsResponse = await instanceApi.sendSMS({
            phones: this.phones,
            message: this.message,
          });

          if (smsResponse.sent) {
            this.successMessage();
            this.phones = [];
            this.message = '';
            this.extendedPanels = [];
          } else {
            this.somethingWrongMessage();
          }
        } else {
          this.warningAtLeastOnePhoneMessage();
        }
      } else {
        this.warningShortMessage();
      }

      this.loading = false;
    },

    successMessage() {
      notification.success({
        message: this.phones.length > 1 ? 'SMS-uri trimise' : 'SMS trimis',
        description: `${this.phones.length > 1 ? 'La urmatoarele numere' : `La urmatorul numar de telefon`} ${this.phones.join(', ')} am trimis mesajul: ${this.message}`,
      });
    },
    warningAtLeastOnePhoneMessage() {
      notification.warning({
        message: 'Eroare',
        description: `Introduceti cel putin un numar de telefon.`,
      });
    },
    warningShortMessage() {
      notification.warning({
        message: 'Eroare',
        description: `Introduceti cel putin 5 caractere in mesajul Dvs.`,
      });
    },
    somethingWrongMessage() {
      notification.warning({
        message: 'Eroare',
        description: `Mesajul SMS nu a putut fi trimis.`,
      });
    },
  },
};
</script>

<style></style>
