<template>
  <div>
    <div class="saas_card hoverable">
      <div class="row-contents">
        <div class="group-text-container">
          <div class="group-text">
            <div class="edit-title">List</div>
          </div>
        </div>
        <div class="metadata">
          <div class="controls">-</div>
        </div>
      </div>
      <div class="details-container">
        <div class="content">Log list</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataProviderId', 'sourceId', 'source'],
  name: 'DataProviderLogs',
  data() {
    return {
      loading: false,
    };
  },
};
</script>
