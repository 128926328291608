<script>
import { h } from 'vue';
import { NewTemplatesMutations } from '@/apps/templateDataSystem/shared/newTemplateDataSystem.store';
import ProviderApi from '@/apps/dataProvider/api/provider.api';
import router from '@/router';
import { notification } from 'ant-design-vue';

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  created() {
    this.localContent = this.content;
  },
  data() {
    return {
      templateRender: null,
      localContent: false,
    };
  },
  watch: {
    content(data) {
      this.localContent = data;
    },
  },
  render() {
    const r = {
      template: this.localContent,
      emits: ['buttonClicked'],
      data() {
        return {
          dataflowLoading: false,
        };
      },
      methods: {
        generatePDF() {
          NewTemplatesMutations.SET_DOWNLOAD_PDF(true);
        },
        goToTemplate(templateId) {
          const menuItemId = this.$route.params.menuItemId ?? null;
          const instanceId = this.$route.params.instanceId ?? null;

          const params = { viewType: 'open', templateId };
          if (this.$route.params.templateId) {
            if (this.$route.params.templateId !== templateId) {
              params.oldTemplateId = this.$route.params.templateId;
            }
          }
          if (menuItemId) {
            params.menuItemId = menuItemId;
          }
          if (instanceId) {
            params.instanceId = instanceId;
          }
          router
            .push({
              name: 'templateDataSystemListOpenTemplate',
              params,
            })
            .catch(() => {});
        },
        async runManualDataflow(providerId) {
          this.dataflowLoading = true;

          const openInstanceId = this.$route.params.instanceId ?? null;
          const templateId = this.$route.params.templateId ?? null;

          try {
            const response = await ProviderApi.runManualDataflow(providerId, {
              openInstanceId,
              templateId,
            });

            const { data } = response;
            console.log(response.headers);
            console.log('RESPONSE DATA:');
            console.log(data);

            const contentDisposition = response.headers['content-disposition'];
            const contentType = response.headers['content-type'];
            if (contentDisposition && contentDisposition.includes('attachment')) {
              // Extract filename from Content-Disposition header
              const filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '');
              const blob = new Blob([response.data], {
                type: contentType || 'application/octet-stream',
              });

              // Log blob details for debugging
              console.log('Blob size:', blob.size);
              console.log('Blob type:', blob.type);

              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              const text = await response.data.text();
              const jsonData = JSON.parse(text);

              const successOrFailure = jsonData.success ? 'success' : 'warning';
              notification[successOrFailure]({
                message: jsonData.success ? 'Finished' : 'Error',
                description: jsonData.message,
              });
            }
          } catch (error) {
            notification.error({
              message: 'Error',
              description: error.message,
            });
          } finally {
            this.dataflowLoading = false;
          }
        },
        trigger(data) {
          if (typeof data === 'object') {
            this.$emit('buttonClicked', data);
          } else {
            const obj = JSON.parse(this.decodeHtmlCharCodes(data));
            this.$emit('buttonClicked', obj);
          }
        },

        decodeHtmlCharCodes(str) {
          return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
          });
        },
      },
    };
    return h(r);
  },
};
</script>
