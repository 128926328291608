import axios from 'axios';

const getInvitations = async ({ type, tenantId, subtenantId }) => {
  const response = await axios.get('/user-management/invitation', {
    params: {
      type,
      tenantId,
      subtenantId,
    },
  });
  return response.data;
};

const getInvitation = async ({ invitationToken }) => {
  const response = await axios.get(`/user-management/public/invitation/${invitationToken}`);
  return response.data;
};

const postInvitation = async ({ recipientEmailList, senderUserId, meta }) => {
  const response = await axios.post(`/user-management/invitation`, {
    senderUserId,
    recipientEmailList,
    meta,
  });
  return response.data;
};

const postInvitationAccept = async ({ invitationToken }) => {
  const response = await axios.post(`/user-management/public/invitation/${invitationToken}/accept`);
  return response.data;
};

const putInvitation = async invitationId => {
  const response = await axios.put(`/user-management/invitation/${invitationId}`);
  return response.data;
};

const deleteInvitation = async invitationId => {
  const response = await axios.delete(`/user-management/invitation/${invitationId}`);
  return response.data;
};

export default {
  getInvitations,
  getInvitation,
  postInvitation,
  postInvitationAccept,
  putInvitation,
  deleteInvitation,
};
