<template>
  <div>
    <a-button @click="onClickRefresh" type="primary" size="large" :loading="loading" style="margin-bottom: 10px"> Refresh </a-button>
    <a-table :columns="joinOutputFields" :data-source="data" rowKey="_id"></a-table>
  </div>
</template>

<script>
import { DataProviderActions, DataProviderGetters } from '@dataProvider/shared/dataProvider.store';

import { providerApi } from '../../../../api';

export default {
  props: ['dataProviderId', 'sourceId', 'source'],
  name: 'DataProviderBuilderData',
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  async mounted() {
    DataProviderActions.calculateJoinsFields();
    this.loading = true;
    this.data = await providerApi.getData(this.dataProviderId);
    this.loading = false;
  },
  computed: {
    joinOutputFields() {
      return DataProviderGetters.getJoinOutputFields().map(j => {
        return {
          title: j.label,
          dataIndex: j.originalId,
          key: j.originalId,
        };
      });
    },
  },
  methods: {
    async onClickRefresh() {
      this.loading = true;
      this.data = await providerApi.getData(this.dataProviderId);
      this.loading = false;
    },
  },
  components: {},
};
</script>
<style scoped></style>
