<template>
  <div>
    <div style="margin-bottom: 10px">Field ID: {{ fieldInput._id }}</div>
    <a-divider />
    <div style="margin-bottom: 10px">
      <label>Label</label>
      <a-input v-model:value="input.label" />
    </div>

    <div style="margin-bottom: 10px">
      <label>Description</label>
      <a-textarea v-model:value="input.description" />
    </div>

    <a-divider />

    <template v-if="input?.structure.type === 'array'">
      <div style="margin-bottom: 10px">
        <div style="margin-bottom: 10px">This is available for Multiple-valued Fields</div>

        <div v-if="prevFieldGroup && (fieldInput?.group ?? '') === ''">
          <div v-if="prevFieldGroup" style="margin-bottom: 10px">
            Join field group:
            <a-button @click="input.group = prevFieldGroup" size="small" type="primary"
              ><strong>{{ prevFieldGroup }}</strong></a-button
            >
          </div>

          <div style="margin-bottom: 10px">or create a new group:</div>
        </div>

        <div v-else>
          <label>Group name</label>
        </div>

        <div>
          <a-input allowClear v-model:value="input.group" placeholder="ex.: field-name-group" />
        </div>

        <div
          v-if="firstFieldInGroup && firstFieldInGroup._id === fieldInput._id && fieldInput?.input?.type === 'input_checkbox_list'"
          style="margin-top: 10px"
        >
          This setting is available if the field is first in group:
          <a-checkbox v-model:checked="input.groupOneChoice" class="fieldRule">
            Show one choice per group item
            <help-icon-popover content="Show one choice per group item." />
          </a-checkbox>
          <a-checkbox v-if="input.groupOneChoice" v-model:checked="input.groupOneChoiceLoadAllChoices" class="fieldRule">
            Direct load all the choices list
            <help-icon-popover content="Direct load all the choices list." />
          </a-checkbox>
        </div>
      </div>
    </template>

    <a-divider />
    <div class="row">
      <a-checkbox v-model:checked="input.isRequired" class="col-12">
        Required
        <help-icon-popover title="Required Field" content="This field will be required." />
      </a-checkbox>

      <a-checkbox v-model:checked="input.isUnique" class="col-12">
        Unique
        <help-icon-popover title="Unique Field" content="This field will be unique." />
      </a-checkbox>

      <a-checkbox v-model:checked="input.isReadOnlyAdd" class="col-12">
        Read only (Add)
        <help-icon-popover title="Read only on Add" content="This field will be read only when adding an instance." />
      </a-checkbox>

      <a-checkbox v-model:checked="input.isReadOnlyEdit" class="col-12">
        Read only (Edit)
        <help-icon-popover title="Read only on Edit" content="This field will be read only when editing an instance." />
      </a-checkbox>

      <a-checkbox
        v-if="input?.structure.type === 'reference' || input?.structure?.elementStructure?.type === 'reference'"
        v-model:checked="input.isDynamic"
        class="col-12"
      >
        Dynamic search
        <help-icon-popover
          title="Dynamic search"
          content="Options will be retrieved dynamically while typing (recommended for references with many instances)."
        />
      </a-checkbox>

      <div v-if="input.widget?.type === 'phone_number'" class="mt-2" style="display: flex; align-items: center">
        <span class="mr-2">Default country code</span>
        <a-select
          show-search
          style="width: 90px"
          v-model:value="input.structure.ruleset.countryCode"
          :options="
            countryCodes.map(code => {
              return { value: code.code, label: code.name };
            })
          "
          :filter-option="(search, option) => option.label.toLowerCase().indexOf(search.toLowerCase()) >= 0"
        >
        </a-select>
      </div>
    </div>
    <a-divider />

    <TypeOptions
      :fieldId="fieldId"
      v-if="input?.structure?.insertValueMode !== 'choices' && input?.structure"
      @update="
        value => {
          input = value;
        }
      "
    />
    <ChoicesOptions
      v-if="input?.structure?.insertValueMode === 'choices' && input?.structure"
      :fieldId="fieldId"
      @update="
        value => {
          input = value;
        }
      "
    />
    <ChoicesOptionsMultiple
      v-else-if="input?.structure?.elementStructure?.insertValueMode === 'choices' && input?.structure"
      :fieldId="fieldId"
      @update="
        value => {
          input = value;
        }
      "
    />
  </div>
</template>

<script>
import countryCodes from '@/core/constants/countryCodes.constant';
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

import { TypeOptions } from './TypeOptions';
import ChoicesOptions from './ChoicesOptions.vue';
import ChoicesOptionsMultiple from './ChoicesOptionsMultiple.vue';

export default {
  name: 'GeneralOptionsIndex',
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    keys: [
      'group',
      'groupOneChoice',
      'groupOneChoiceLoadAllChoices',
      'structure',
      'widget',
      'label',
      'description',
      'isRequired',
      'isUnique',
      'isDynamic',
      'structure',
      'isReadOnlyAdd',
      'isReadOnlyEdit',
    ],
  },
  data() {
    return {
      countryCodes,
    };
  },
  computed: {
    firstFieldInGroup() {
      const group = this.fieldInput?.group;
      if (!group || group === '') {
        return null;
      }
      const findFields = (this.allFields ?? []).filter(item => {
        return item.group && item.group !== '' && item.group === group;
      });
      return findFields.length ? findFields[0] : null;
    },
    prevFieldGroup() {
      const fieldIndex = (this.allFields ?? []).findIndex(item => item._id === this.fieldInput._id);
      const prevField = this.allFields[fieldIndex - 1] ?? null;
      if (prevField) {
        if ((prevField?.group ?? '') !== '') {
          return prevField.group;
        }
      }
      return null;
    },
  },
  components: {
    TypeOptions,
    ChoicesOptions,
    ChoicesOptionsMultiple,
  },
};
</script>

<style scoped>
.fieldRule {
  margin: 5px 0 !important;
}
</style>
