<template>
  <div style="padding-top: 20px">
    <div v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <router-link
        v-for="authRest in dataAuthRestList"
        :key="authRest.id"
        :to="{
          name: 'DataAuthRestBuilder',
          params: { authRestId: authRest._id },
        }"
        custom
        v-slot="{ navigate }"
      >
        <div class="card mb-10 pointer">
          <div class="card-body" @click="navigate">
            {{ authRest.name }}
          </div>
          <a-popconfirm @confirm="deleteDataAuthRest(authRest._id)" title="Are you sure you what to delete？" ok-text="Yes" cancel-text="No">
            <div class="delete-button">
              <a-button :loading="dataAuthRestLoading === authRest._id" type="primary" danger><DeleteOutlined /></a-button>
            </div>
          </a-popconfirm>
        </div>
      </router-link>
      <a-button :loading="addNewLoading" @click="addNew()"><PlusOutlined /> Adauga new</a-button>
    </div>
  </div>
</template>

<script>
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { DataAuthRestActions, DataAuthRestGetters } from '@dataAuthRest/shared/dataAuthRest.store';

export default {
  name: 'DataAuthRestList',
  components: { DeleteOutlined, PlusOutlined },
  data() {
    return {
      loading: false,
      addNewLoading: false,
      dataAuthRestLoading: null,
    };
  },

  async mounted() {
    await DataAuthRestActions.fetchList();
  },

  computed: {
    dataAuthRestList: DataAuthRestGetters.getDataAuthRestList,
  },
  methods: {
    async deleteDataAuthRest(dataAuthRestId) {
      this.dataAuthRestLoading = dataAuthRestId;
      await DataAuthRestActions.deleteOne(dataAuthRestId);
      this.dataAuthRestLoading = null;
    },
    async addNew() {
      this.addNewLoading = true;
      await DataAuthRestActions.addNew(`New auth rest${!this.dataAuthRestList.length ? '' : ` ${this.dataAuthRestList.length + 1}`}`);
      this.addNewLoading = false;
    },
  },
};
</script>
<style scoped>
.delete-button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
.card-body {
  padding: 10px;
  margin-right: 40px;
}
</style>
