import { reactive } from 'vue';
import LogRocket from 'logrocket';
import { authApi } from '@userManagement/api';

import { setAxioxAuthorizationHeader } from './auth.helper';

const initialState = {
  user: null,
};

const state = reactive({ ...initialState });

const Getters = {
  getUser: () => {
    return state.user;
  },
};

const Mutations = {
  SET_USER: user => {
    state.user = user;
  },
  UNSET_USER: () => {
    state.user = null;
  },
};

const Actions = {
  async login(email, password) {
    const { user, accessToken, defaultUrl } = await authApi.postLogin(email, password);
    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });
    return defaultUrl;
  },
  async silentLogin() {
    const { user, accessToken } = await authApi.putLoginRefresh();
    Mutations.SET_USER(user);
    setAxioxAuthorizationHeader(accessToken);
    LogRocket.identify(user._id, {
      name: `${user.firstName}, ${user.lastName}`,
      email: user.email,
    });
    return { accessToken };
  },
  async logout() {
    await authApi.deleteLogout();
    Mutations.UNSET_USER();
  },
};

export const UserGetters = Getters;
export const UserMutations = Mutations;
export const UserActions = Actions;
