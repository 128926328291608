<template>
  <a-card :title="blueprint.name" style="height: 300px">
    <a-card-grid style="width: 100%" :hoverable="false">
      <p v-if="blueprint.description">{{ blueprint.description }}</p>
      <p v-else>No description. {}</p>

      <BluprintSelectTenantsApps :input="blueprint" :tenantsAndApps="tenantsAndApps" @updateInput="updateInput" style="height: 105px" />
    </a-card-grid>
    <a-card-grid
      style="width: 50%; height: 15px; padding-top: 15px; padding-bottom: 35px; text-align: center; vertical-align: center; background-color: #fafafa"
      :hoverable="false"
    >
      <a-tooltip title="Edit Form" style="margin-top: -15px">
        <router-link
          :to="{
            name: routerLink,
            params: { blueprintId: blueprint._id },
          }"
          style="display: block; width: 100%"
        >
          <span>
            <FormOutlined key="form" />
          </span>
        </router-link>
      </a-tooltip>
    </a-card-grid>
    <a-card-grid
      style="width: 50%; height: 15px; padding-top: 15px; padding-bottom: 33px; text-align: center; vertical-align: center; background-color: #fafafa"
      :hoverable="false"
    >
      <a-tooltip title="More">
        <a-dropdown :trigger="['click']">
          <EllipsisOutlined key="ellipsis" />
          <template #overlay>
            <a-menu @click="onMenuItemClick">
              <a-menu-item key="edit">Edit Info</a-menu-item>
              <a-menu-item key="delete">Delete</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-tooltip>
    </a-card-grid>
  </a-card>
</template>

<script>
import { EllipsisOutlined, FormOutlined } from '@ant-design/icons-vue';
import BluprintSelectTenantsApps from '@dataSystem/views/BlueprintList/components/BluprintSelectTenantsApps';

export default {
  props: ['blueprint', 'tenantsAndApps', 'routerLink'],
  emits: ['edit', 'delete'],
  components: {
    BluprintSelectTenantsApps,
    EllipsisOutlined,
    FormOutlined,
  },
  methods: {
    updateInput(data) {
      this.$emit('edit', data, false);
    },
    onMenuItemClick({ key }) {
      if (key === 'edit') {
        this.$emit('edit', this.blueprint._id, true);
        return;
      }
      if (key === 'delete') {
        this.$emit('delete', this.blueprint._id);
      }
    },
  },
};
</script>

<style>
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #1890ff;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
</style>
