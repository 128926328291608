import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const getOneTemplateById = async () => {
  try {
    const response = await axios.get(`/public/template-management/${SlugGetters.getSubtenantSlug()}/${SlugGetters.getTemplateId()}`);
    return response.data;
  } catch (error) {
    window.location.href = '/auth/login';
    return {
      error: true,
      response: error.response,
    };
  }
};
const getOneBlueprintById = async (templateId, blueprintId) => {
  const response = await axios.get(`/public/template-management/blueprints/${SlugGetters.getSubtenantSlug()}/${blueprintId}`);
  return response.data;
};
const instancesGetOne = async (templateId, blueprintId, mainInstanceId) => {
  try {
    const response = await axios.get(
      `/public/template-management/instancesGetOne/${SlugGetters.getSubtenantSlug()}/${SlugGetters.getTemplateId()}?blueprintId=${blueprintId}&mainInstanceId=${mainInstanceId}`
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};
const instancesGetAll = async (templateId, blueprintId, params, filterConditions, openInstance = null) => {
  try {
    const response = await axios.post(
      `/public/template-management/instancesGetAll/${SlugGetters.getSubtenantSlug()}/${SlugGetters.getTemplateId()}/${blueprintId}`,
      {
        params,
        filterConditions,
        openInstance,
      }
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const getAllWithFields = async (templateId, blueprintIds) => {
  try {
    const response = await axios.post(
      `/public/template-management/blueprints-fields/${SlugGetters.getSubtenantSlug()}/${SlugGetters.getTemplateId()}`,
      { ids: blueprintIds }
    );
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  getOneById: getOneTemplateById,
  instancesGetOne,
  instancesGetAll,
  getAllWithFields,
  getOneBlueprintById,
};
