import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const {
  getAppUrl,
  // getSubtenantUrl
} = SlugGetters;

const getAll = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/data-auth-rest/list`, data);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const getAllWithChainedRequests = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/data-auth-rest/listFull`, data);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const getOne = async authRestId => {
  try {
    const response = await axios.get(`${getAppUrl()}/data-auth-rest/${authRestId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

const postOne = async data => {
  try {
    const response = await axios.post(`${getAppUrl()}/data-auth-rest/add`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const editOne = async (authRestId, data) => {
  try {
    const response = await axios.post(`${getAppUrl()}/data-auth-rest/edit/${authRestId}`, { data });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteOne = async authRestId => {
  try {
    await axios.delete(`${getAppUrl()}/data-auth-rest/${authRestId}`);
    return true;
  } catch (error) {
    return null;
  }
};

const testEndpoint = async (authRestId, data = {}) => {
  try {
    const response = await axios.post(`${getAppUrl()}/data-auth-rest/${authRestId}/test`, { data });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

export default {
  getAll,
  getAllWithChainedRequests,
  getOne,
  postOne,
  editOne,
  deleteOne,
  testEndpoint,
};
