<template>
  <div>
    <loading-spinner v-if="!layoutBuild || layoutLoading" />
    <div v-else-if="layout">
      <div class="row">
        <div class="col-3">
          <h3>Menus</h3>

          <div class="menu-container">
            <draggable class="list-menu" :list="menus" item-key="_id" group="layout">
              <template #item="{ element }">
                <div v-if="element.active" class="drag-item drag-only-this">
                  {{ element.name }}
                </div>
              </template>
            </draggable>
          </div>
        </div>

        <div class="col-9">
          <h3>Layout</h3>

          <div class="layout-container">
            <draggable class="layout-left" :list="layout.leftColumn" item-key="_id" group="layout">
              <template #item="{ element }">
                <div class="drag-item drag-only-this">
                  {{ element.name }}
                </div>
              </template>
            </draggable>

            <draggable class="layout-center" :list="layout.centerColumn" item-key="_id" handle=".drag-only-this" group="layout">
              <template #item="{ element }">
                <div :class="'drag-item' + (element._id === '_content' ? ' not-drag' : ' drag-only-this')">
                  {{ element.name }}
                </div>
              </template>
            </draggable>

            <draggable class="layout-right" :list="layout.rightColumn" item-key="_id" group="layout">
              <template #item="{ element }">
                <div class="drag-item drag-only-this">
                  {{ element.name }}
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MenuGetters } from '@menuBuilder/shared/menuBuilder.store';

import draggable from 'vuedraggable';
import { menuBuilderApi } from '@menuBuilder/api';

import { message } from 'ant-design-vue';

const defaultLayout = {
  name: '',
  description: '',

  leftColumn: [],
  centerColumn: [],
  centerContentPosition: 0,
  rightColumn: [],

  defaultLayout: true,
};

export default {
  name: 'MenuLayout',
  data() {
    return {
      layoutLoading: false,
      layoutBuild: false,
      layout: { ...defaultLayout },
    };
  },
  computed: {
    menuLoading: MenuGetters.getMenuLoading,
    usedMenus() {
      return [
        ...this.layout.leftColumn.map(item => item._id),
        ...this.layout.centerColumn.filter(item => item._id !== '_content').map(item => item._id),
        ...this.layout.rightColumn.map(item => item._id),
      ];
    },
    menus() {
      return MenuGetters.getMenus()
        .filter(item => !this.usedMenus.includes(item._id))
        .map(item => {
          return { ...item, id: item._id };
        });
    },

    updatedLayout() {
      return {
        ...this.layout,
        leftColumn: this.layout.leftColumn.map(item => item._id),
        centerColumn: this.layout.centerColumn.map(item => item._id),
        centerContentPosition: this.layout.centerColumn.indexOf(this.layout.centerColumn.find(item => item._id === '_content')),
        rightColumn: this.layout.rightColumn.map(item => item._id),
      };
    },
  },
  watch: {
    updatedLayout: {
      deep: true,
      async handler(value, oldValue) {
        if (this.layoutBuild) {
          if (!oldValue.defaultLayout) {
            await this.updateLayout(value);
          }
        }
      },
    },
  },
  async created() {
    this.layoutBuild = false;
    this.layoutLoading = true;

    const layout = await menuBuilderApi.getLayout();
    this.layout = {
      ...(layout ?? this.layout),
      defaultLayout: false,
    };
    this.layout.centerColumn.splice(layout?.centerContentPosition, 0, {
      name: 'CONTENT',
      id: '_content',
      _id: '_content',
    });

    this.layoutLoading = false;
    this.layoutBuild = true;
  },
  components: {
    draggable,
  },
  methods: {
    async updateLayout(updatedLayout) {
      const form = {
        ...updatedLayout,
        centerColumn: [...updatedLayout.centerColumn.filter(item => item !== '_content')],
      };
      const layout = await menuBuilderApi.updateOneLayout(form);
      message.success('Layout updated!');

      return layout;
    },
  },
};
</script>

<style scoped>
.menu-container {
  border-radius: 4px;
  border: 1px dashed #ddd;
}
.menu-container .list-menu {
  min-height: 54px;
}

.layout-container {
  display: flex;
  border: 1px dashed #ddd;
  border-radius: 4px;
}

.layout-left {
  width: 25%;
  border: 1px dashed #ddd;
  border-radius: 4px;
  margin: 5px;
  min-height: 50px;
}
.layout-center {
  width: 50%;
  border: 1px dashed #ddd;
  border-radius: 4px;
  margin: 5px;
  min-height: 50px;
}
.layout-right {
  width: 25%;
  border: 1px dashed #ddd;
  border-radius: 4px;
  margin: 5px;
  min-height: 50px;
}
.drag-item {
  border: 1px solid #9ebeec;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  background-color: #fff;
  text-align: center;
}

.not-drag {
  text-align: center;
  background-color: rgba(155, 94, 94, 0.1);
  border-color: #e09393;
}
</style>
