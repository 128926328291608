<template>
  <div>
    <UserListManager
      :visible="UserListManagerProps.visible"
      :rank="UserListManagerProps.rank"
      :tenantId="tenantId"
      :subtenantId="UserListManagerProps.subtenantId"
      @close="closeUserListManager"
    />
    <ApiKeysList
      v-if="ApiKeyListManagerProps.subtenantId"
      :visible="ApiKeyListManagerProps.visible"
      :rank="ApiKeyListManagerProps.rank"
      :tenantId="tenantId"
      :subtenantId="ApiKeyListManagerProps.subtenantId"
      :subtenantSlug="ApiKeyListManagerProps.subtenantSlug"
      @close="closeApiKeyListManager"
    />
    <div v-if="!application" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
    <div v-else>
      <h2 style="margin: 0">{{ application.name }}</h2>
      <ul class="saas_simple-breadcrumb">
        <li v-if="tenant">{{ tenant.name }}</li>
        <li>{{ application.name }}</li>
      </ul>

      <a-row v-if="isUserTenantAdmin">
        <a-button type="primary" @click="createEditSubtenant('TASBlueprintForm', 'Create Subtenant')"> <PlusOutlined /> Create Subtenant </a-button>
      </a-row>

      <br />
      <br />
      <h3>
        <BankOutlined />
        Subtenants list
        <a-badge
          :count="application.subtenants ? application.subtenants.length : 0"
          :number-style="{
            backgroundColor: '#fff',
            color: '#999',
            boxShadow: '0 0 0 1px #d9d9d9 inset',
          }"
        />
      </h3>
      <a-alert v-if="!application.subtenants || !application.subtenants.length" banner message="No subtenants added" />

      <template v-if="application.subtenants">
        <div class="saas_card mb-10 hoverable" v-for="subtenant in application.subtenants" :key="subtenant._id" data-cy="cardSubtenant">
          <div class="row-contents">
            <div class="group-text-container" @click="selectSubtenant(subtenant.slug)">
              <a data-cy="linkSubtenantSelect">
                <div class="group-text">
                  <span class="title">{{ subtenant.name }}</span>
                </div>
              </a>
            </div>
            <div class="metadata">
              <div class="controls">
                <a-button
                  v-if="isUserTenantAdmin"
                  @click="() => openApiKeyListManager('subtenant-manager', subtenant._id, subtenant.slug)"
                  class="mr-10"
                >
                  <TeamOutlined />
                  Api Key
                </a-button>
                <a-button v-if="isUserTenantAdmin" @click="() => openUserListManager('subtenant-manager', subtenant._id)" class="mr-10">
                  <TeamOutlined /> Managers
                </a-button>
                <a-button v-if="isUserSubtenantManager(subtenant)" @click="() => openUserListManager('subtenant-user', subtenant._id)" class="mr-10">
                  <TeamOutlined /> Users
                </a-button>
                <template v-if="isUserTenantAdmin || isUserSubtenantManager(subtenant)">
                  <a-tooltip placement="top">
                    <template #title>
                      <span>Edit</span>
                    </template>
                    <a-button
                      @click="createEditSubtenant('TASBlueprintForm', 'Edit Subtenant', true, subtenant)"
                      class="mr-10"
                      v-if="isUserSubtenantManager(subtenant)"
                    >
                      <EditOutlined />
                    </a-button>
                  </a-tooltip>

                  <a-popconfirm
                    placement="topLeft"
                    :title="'Are you sure you want to delete `' + subtenant.name + '`?'"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="removeSubtenant(subtenant._id)"
                  >
                    <a-button v-if="isUserTenantAdmin"><DeleteOutlined /></a-button>
                  </a-popconfirm>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { BankOutlined, DeleteOutlined, EditOutlined, PlusOutlined, TeamOutlined } from '@ant-design/icons-vue';
import { TenantsActions } from '@tenants/shared/tenants.store';

import UserListManager from '@userManagement/components/UserListManager/UserListManager.vue';

import { UserGetters } from '@userManagement/user.store';
import router from '@/router';
import { ApiKeysList } from '@/apps/tenants/views/TenantList/components/ApiKeysManagement';

export default {
  props: ['tenant', 'tenantId', 'appId', 'application'],
  emits: ['openModal'],
  data() {
    return {
      loading: false,

      UserListManagerProps: {
        visible: false,
        rank: null,
        subtenantId: null,
      },
      ApiKeyListManagerProps: {
        visible: false,
        rank: null,
        subtenantId: null,
        subtenantSlug: null,
      },
    };
  },

  computed: {
    user: UserGetters.getUser,
    isUserTenantAdmin() {
      if (this.user.isSuperAdmin) {
        return true;
      }
      return this.tenant.adminUserIdList.indexOf(this.user._id) !== -1;
    },
  },
  methods: {
    isUserSubtenantManager(subtenant) {
      if (this.isUserTenantAdmin) {
        return true;
      }
      return subtenant.managerUserIdList.indexOf(this.user._id) !== -1;
    },
    selectSubtenant(subtenantSlug) {
      router.push({
        name: 'templateDataSystemBuilder',
        params: {
          tenantSlug: this.tenant.slug,
          appSlug: this.application.slug,
          subtenantSlug,
        },
      });
    },
    createEditSubtenant(componentTarget, componentName, edit, editData) {
      this.$emit('openModal', {
        componentTarget,
        componentName,
        edit,
        editData,
        type: 'subtenant',
      });
    },
    removeSubtenant(subtenantId) {
      TenantsActions.deleteOneSubtenant(this.tenantId, this.appId, subtenantId);
    },
    openUserListManager(rank, subtenantId) {
      this.UserListManagerProps = {
        visible: true,
        rank,
        subtenantId,
      };
    },
    closeUserListManager() {
      this.UserListManagerProps = {
        visible: false,
        rank: null,
        subtenantId: null,
      };
    },
    openApiKeyListManager(rank, subtenantId, subtenantSlug) {
      this.ApiKeyListManagerProps = {
        visible: true,
        rank,
        subtenantId,
        subtenantSlug,
      };
    },
    closeApiKeyListManager() {
      this.ApiKeyListManagerProps = {
        visible: false,
        rank: null,
        subtenantId: null,
        subtenantSlug: null,
      };
    },
  },
  components: {
    ApiKeysList,
    UserListManager,
    BankOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    TeamOutlined,
  },
};
</script>
<style scoped></style>
