import FieldMapperBuilder from './views/fieldMapperBuilder/fieldMapperBuilder.vue';
import fieldMapperList from './views/fieldMapperList/fieldMapperListBuilder.vue';

const fieldMapperRoutes = [
  {
    path: '/',
    name: 'FieldMapperList',
    meta: {
      menuTitle: 'Mappers',
    },
    component: fieldMapperList,
  },
  {
    path: '/:fieldMapperId',
    name: 'FieldMapperBuilder',
    meta: {
      menuTitle: 'Mappers',
    },
    component: FieldMapperBuilder,
  },
];

export default fieldMapperRoutes;
