<template>
  <loading-spinner v-if="!blueprint" />
  <div v-else style="padding: 20px">
    <BlueprintFormLinkSharing :visible="isLinkSharingVisible" :blueprintId="blueprintId" @close="closeLinkSharingDrawer" />

    <template v-if="canCreateInstance">
      <div style="margin-bottom: 15px">
        <label style="margin-right: 5px; font-weight: 500"> Create new entry </label>
        <a-button v-if="this.blueprint.linkSharing.isEnabled" @click="openLinkSharingDrawer"> <ShareAltOutlined /> Share </a-button>
      </div>
      <template v-if="userRoles.length > 1">
        <span style="margin-right: 5px; font-weight: 500">as</span>
        <a-select v-model:value="selectedOwnerRoleId" style="width: 200px; margin-bottom: 20px">
          <a-select-option v-for="role in userRolesWhichCanCreate" :key="role._id" :value="role._id">
            {{ role.name }}
          </a-select-option>
        </a-select>
      </template>

      <InstanceRowCreate :key="`create_${blueprint._id}`" :blueprintId="blueprint._id" @submit="onRowCreateSubmit" />
      <br />
    </template>

    <InstanceCreateOrEdit
      :visible="InstanceCreateOrEditProps.isVisible"
      @close="closeInstanceCreateOrEdit"
      :blueprintId="blueprintId"
      :instanceId="InstanceCreateOrEditProps.instanceId"
    />
    <h1>{{ blueprint.name }} Entries</h1>

    <a-button v-if="canCreateInstance" type="primary" style="margin-bottom: 15px" @click="InstanceCreateOrEditProps.isVisible = true">
      Create entry
    </a-button>

    <InstanceTable
      :key="blueprint._id"
      :blueprint="blueprint"
      :fieldsById="fieldsById"
      :instanceList="instanceList"
      :instanceListTotalCount="instanceListTotalCount"
      :canModifyInstanceMap="canModifyInstanceMap"
      @fetchInstanceList="onFetchInstanceList"
      @editInstance="onEditInstance"
      @deleteInstance="onDeleteInstance"
    />
  </div>
</template>

<script>
import { InstanceCreateOrEdit } from '@dataSystem/components/InstanceCreateOrEdit';

import { BlueprintFormLinkSharing } from '@dataSystem/components/BlueprintFormLinkSharing';

import { instanceApi, blueprintApi } from '@dataSystem/api';

import { roleSubtenantApi } from '@roleManagement/api';
import { ShareAltOutlined } from '@ant-design/icons-vue';
import InstanceTable from './InstanceTable.vue';
import InstanceRowCreate from './InstanceRowCreate.vue';

export default {
  props: ['blueprintId'],
  components: {
    InstanceCreateOrEdit,
    InstanceTable,
    InstanceRowCreate,
    BlueprintFormLinkSharing,
    ShareAltOutlined,
  },
  data() {
    return {
      InstanceCreateOrEditProps: {
        isVisible: false,
        instanceId: null,
      },
      canModifyInstanceMap: null,
      instanceList: [],
      instanceListTotalCount: null,
      fieldsById: null,
      blueprint: null,
      query: null,
      userRoles: [],
      selectedOwnerRoleId: null,
      isLinkSharingVisible: false,
    };
  },
  created() {
    this.init();
    this.fetchUserRoles();
  },
  watch: {
    blueprintId() {
      this.init();
    },
    userRolesWhichCanCreate() {
      if (this.userRolesWhichCanCreate?.length > 0) {
        this.selectedOwnerRoleId = this.userRolesWhichCanCreate[0]._id;
      }
    },
  },
  computed: {
    userRolesWhichCanCreate() {
      return this.userRoles.filter(role => {
        const permission = role.permissionByBlueprintId[this.blueprintId];
        if (!permission) {
          return false;
        }
        return permission.createAuthority.mode === 'yes';
      });
    },
    canCreateInstance() {
      return this.userRolesWhichCanCreate.length > 0;
    },
  },
  methods: {
    async fetchUserRoles() {
      this.userRoles = await roleSubtenantApi.getAllForUser();
    },
    async fetchInstanceList() {
      const { totalCount, instanceList, canModifyInstanceMap } = await instanceApi.getAll(this.blueprintId, this.query);
      this.instanceList = instanceList;
      this.instanceListTotalCount = totalCount;
      this.canModifyInstanceMap = canModifyInstanceMap;
    },
    async init() {
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.blueprintId);
      this.blueprint = blueprint;
      this.fieldsById = fieldsById;
      this.query = null;
      this.instanceListTotalCount = null;
      this.InstanceCreateOrEditProps.instanceId = null;
      await this.fetchInstanceList();
    },
    async closeInstanceCreateOrEdit() {
      this.InstanceCreateOrEditProps.isVisible = false;
      await this.fetchInstanceList();
    },
    async onEditInstance(instanceId) {
      this.InstanceCreateOrEditProps.instanceId = instanceId;
      this.InstanceCreateOrEditProps.isVisible = true;
    },
    async onDeleteInstance(instanceId) {
      await instanceApi.deleteOne(this.blueprintId, instanceId);
      await this.fetchInstanceList();
    },
    async onFetchInstanceList(query) {
      this.query = query;
      await this.fetchInstanceList();
    },
    async onRowCreateSubmit(fieldIdToValue) {
      const newInstance = await instanceApi.postOne(this.blueprintId, this.selectedOwnerRoleId, fieldIdToValue);
      this.instanceList.unshift(newInstance);
      this.canModifyInstanceMap[newInstance._id] = true;
    },
    openLinkSharingDrawer() {
      this.isLinkSharingVisible = true;
    },
    closeLinkSharingDrawer() {
      this.isLinkSharingVisible = false;
    },
  },
};
</script>

<style></style>
