<template>
  <a-modal v-model:open="modalView" title="Configurare order" @cancel="close" @ok="close">
    <a-row style="margin-bottom: 10px">
      <a-col :span="12" style="padding: 3px 5px">
        Field Id:<br />
        <a-select
          v-model:value="localTemplate.object.view.default.order.fieldId"
          :options="selectedBlueprintFieldsOptions"
          style="width: 100%"
        ></a-select>
      </a-col>

      <a-col :span="12" style="padding: 3px 5px">
        Order:<br />
        <a-select v-model:value="localTemplate.object.view.default.order.order" style="width: 100%">
          <a-select-option value="ascend"> Ascend </a-select-option>
          <a-select-option value="descend"> Descend </a-select-option>
        </a-select>
      </a-col>
    </a-row>

    <template #footer>
      <a-button key="submit" type="primary" @click="close"> Ok </a-button>
    </template>
  </a-modal>
</template>

<script>
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';

export default {
  name: 'ElementOrder',
  props: ['selectedBlueprint', 'template', 'modalConfigOrderVisible'],
  emits: ['update:modalConfigOrderVisible'],
  data() {
    return {
      modalView: this.modalConfigOrderVisible,
      localTemplate: this.template ?? null,
    };
  },
  mounted() {
    this.localTemplate = this.template ?? null;
  },
  watch: {
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('update:modalConfigOrderVisible', false);
    },
  },
  computed: {
    selectedBlueprintFieldsOptions() {
      const options = [];

      if (this.selectedBlueprint && Array.isArray(this.selectedBlueprint.fields)) {
        this.selectedBlueprint.fields.forEach(field => {
          options.push({
            value: field._id,
            label: field.label,
          });
        });
      }

      options.push({
        value: '_created_at',
        label: 'Created at',
      });

      return options;
    },
  },
};
</script>
