<template>
  <a-row style="margin-bottom: 15px" v-if="input.ruleset.useDescription === true">
    <a-col :span="8">
      <label>Description Visibility</label>
      <a-select v-model:value="visibility" default-value="visible" style="width: 200px; display: block">
        <a-select-option value="visible">Visible</a-select-option>
        <a-select-option value="hidden">Hidden</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="8">
      <label>Description Placement</label>
      <a-select v-model:value="input.options.descriptionPlacement" default-value="below" style="width: 200px; display: block">
        <a-select-option value="below">Below input</a-select-option>
        <a-select-option value="above">Above input</a-select-option>
      </a-select>
    </a-col>
  </a-row>
</template>

<script>
import FieldInputOptionsMixin from '../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'widget',
    keys: ['options', 'ruleset'],
  },
  data() {
    return {
      visibility: 'visible',
    };
  },
  mounted() {
    this.visibility = this.input.options.isDescriptionVisible ? 'visible' : 'hidden';
  },
  watch: {
    visibility() {
      this.input.options.isDescriptionVisible = this.visibility === 'visible';
    },
  },
};
</script>

<style></style>
