import axios from 'axios';

const getBlueprintFromSlug = async linkSharingSlug => {
  const response = await axios.get(`/public/data-system/blueprint-from-slug/${linkSharingSlug}?`);
  return response.data;
};

export default {
  getBlueprintFromSlug,
};
