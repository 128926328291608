import { BlueprintList } from '@dataSystem/views/BlueprintList';
import { BlueprintFormBuilder } from '@dataSystem/views/BlueprintFormBuilder';
import TenatsList from './views/TenantList/TenantBuilder.vue';

const tenantsRoutes = [
  {
    path: '/',
    name: 'TenantsList',
    meta: {
      menuTitle: 'Tenants',
    },
    component: TenatsList,
  },
  {
    path: '/:tenantSlug',
    name: 'Tenant',
    component: TenatsList,
  },
  {
    path: '/:tenantSlug/:appSlug',
    name: 'TenantApplication',
    component: TenatsList,
  },
  {
    path: '/blueprint/list',
    name: 'globalBlueprintList',
    meta: {
      menuTitle: 'Business Objects',
    },

    component: BlueprintList,
  },
  {
    path: '/blueprint/:blueprintId/form-builder',
    name: 'globalBlueprintFormBuilder',
    meta: {
      menuTitle: 'Form Builder',
    },
    component: BlueprintFormBuilder,
    props: true,
  },
];

export default tenantsRoutes;
