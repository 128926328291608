<template>
  <div>
    <h2>Endpoint destination</h2>
    <div class="saas_card mb-10">
      <div class="row-contents">
        <div class="group-text-container">
          <div class="group-text">
            <a-row>
              <a-col :span="17">
                <p><a-input addon-before="Url" /></p>
              </a-col>
              <a-col :span="3">
                <div style="text-align: center; padding-top: 4px">Output</div>
              </a-col>
              <a-col :span="4">
                <a-select default-value="json" style="width: 100%">
                  <a-select-option value="json">json</a-select-option>
                  <a-select-option value="xml">XML</a-select-option>
                  <a-select-option value="csv">CSV</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <p>
              Accepted methods:
              <a-select default-value="GET" style="min-width: 250px" mode="multiple">
                <a-select-option value="GET">GET</a-select-option>
                <a-select-option value="POST">POST</a-select-option>
                <a-select-option value="PUT">PUT</a-select-option>
              </a-select>
            </p>
            <p>Generate: apiKey</p>
          </div>
        </div>
      </div>
    </div>

    <drop @drop="onDropField">
      <h3>Fields</h3>
      <div>Drag fields here</div>
      <template v-for="destination in destinations.fieldMapper" :key="destination._id">
        <div class="saas_card mb-10" v-for="field in destination.boundFields" :key="field._id">
          <div class="row-contents">
            <div class="dataRow">
              <div class="cell">
                <div class="group-text-container group-text center" v-if="getDatalowField(field.dataProviderFieldId)">
                  <strong>
                    {{ getDatalowField(field.dataProviderFieldId).label }}
                  </strong>
                  <a-popconfirm placement="topRight" ok-text="Yes" cancel-text="No" @confirm="removeBindFiled">
                    <template #title>
                      <p>Are you sure you want to delete?</p>
                    </template>
                    <a-button :loading="removeBindPreloader" size="small" style="position: absolute; right: 19px">
                      <DeleteOutlined />
                    </a-button>
                  </a-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </drop>
  </div>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import { Drop } from 'vue-easy-dnd';

export default {
  props: ['destinationType', 'outputProviderFields', 'destinations'],
  emits: ['pushFieldMapper', 'pushFieldMapperMappedFields'],
  data() {
    return {
      loading: false,
      removeBindPreloader: false,
    };
  },

  computed: {},
  methods: {
    getDatalowField(dataProviderId) {
      return this.outputProviderFields.find(f => {
        return f.originalId === dataProviderId;
      });
    },
    onDropField(e) {
      if (!this.destinations.fieldMapper.length) {
        this.$emit('pushFieldMapper', {
          type: this.destinationType,
          boundFields: [],
        });
      }
      this.$emit('pushFieldMapperMappedFields', {
        dataProviderFieldId: e.data._id,
      });
    },

    removeBindFiled() {},
  },
  components: {
    Drop,
    DeleteOutlined,
  },
};
</script>
<style scoped>
.dnd-drag.disabled .saas_card {
  opacity: 0.5;
  background-color: #ddd;
}

.dnd-drop {
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  min-height: 100px;
}
.drag-in {
  cursor: move !important;
}

.drop-allowed {
  background-color: rgba(0, 255, 0, 0.08);
}

.drop-forbidden {
  background-color: rgba(255, 0, 0, 0.2);
}

.drop-in {
  border: 1px dashed rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}
</style>
