<template>
  <div>
    <div style="margin: 10px 0px">
      <label>Default value</label>
      <a-input-number
        style="display: block; width: 100%"
        placeholder="No default value set"
        v-model:value="input.defaultValue"
        @update="
          updatedValue => {
            input.defaultValue = updatedValue;
          }
        "
        type="number"
      />
    </div>
    <div style="margin: 10px 0px">
      <label>Minimum value</label>
      <a-input-number style="display: block; width: 100%" placeholder="No minimum set" v-model:value="input.minValue" />
    </div>
    <div style="margin: 10px 0px">
      <label>Maximum value</label>
      <a-input-number style="display: block; width: 100%" placeholder="No maximum set" v-model:value="input.maxValue" />
    </div>
    <div style="margin: 10px 0px">
      <label>Decimals</label>
      <a-input-number style="display: block; width: 100%" placeholder="No decimals set" v-model:value="input.decimalPlaces" :min="0" :max="16" />
    </div>
  </div>
</template>

<script>
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'structure.options',
    keys: ['minValue', 'maxValue', 'decimalPlaces', 'defaultValue'],
  },
  methods: {
    validateInput(input) {
      if (
        ((input.minValue === null || typeof input.minValue === 'number') && (input.maxValue === null || typeof input.maxValue === 'number')) ||
        input.decimalPlaces === null ||
        typeof input.decimalPlaces === 'number'
      ) {
        return { isInputValid: true, validatedInput: input };
      }
      return { isInputValid: false };
    },
  },
};
</script>

<style></style>
