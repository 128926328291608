<template>
  <div>
    <div v-if="!templateLoading" id="element-to-convert">
      <RecursiveColumns
        id="pdf-content"
        :lastDepth="false"
        v-if="template && !template?.renderOnlyForXML"
        :viewType="viewType"
        :template="template"
        @exportXls="exportXls"
      />
    </div>
    <template v-else>
      <loading-spinner />
    </template>
  </div>
</template>

<script>
import RecursiveColumns from '@templateDataSystem/views/components/RecursiveColumns';

import { SlugGetters } from '@/slug.store';

import { NewTemplatesActions, NewTemplatesGetters, NewTemplatesMutations } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import _ from 'lodash';
import { FilterConditions } from '@templateDataSystem/shared/FilterConditions';
import { exportsApi } from '@dataSystem/api';

export default {
  name: 'PublicTemplateDataSystemBuilder',
  components: {
    RecursiveColumns,
  },
  props: ['menuItem'],
  updated() {},
  computed: {
    downloadPDF: NewTemplatesGetters.getDownloadPDF,
    downloadXLSX: NewTemplatesGetters.getDownloadXLSX,
    tenantUrl: SlugGetters.getTenantUrl,
    appUrl: SlugGetters.getAppUrl,
    subtenantUrl: SlugGetters.getSubtenantUrl,
    templateLoading: NewTemplatesGetters.getTemplateLoading,

    template() {
      return NewTemplatesGetters.getTemplate('main');
    },
  },
  data() {
    return {
      loading: false,
      htmlToPdfOptions: {
        margin: 5,

        filename: 'test-pdf',
      },

      viewType: this.$route.params.viewType ?? null,
      templateId: this.$route.params.templateId ?? null,
      subtenantSlug: this.$route.params.subtenantId ?? null,
      instanceId: this.$route.params.instanceId ?? null,

      isLinkSharingVisible: false,
      // template:null,
    };
  },
  async created() {
    this.loading = true;
    await NewTemplatesActions.init(this.templateId, this.instanceId, true);
    this.loading = false;
  },
  watch: {
    async downloadPDF(status) {
      if (status.actionButton) {
        await this.generatePDF();
        NewTemplatesMutations.SET_DOWNLOAD_PDF(true, false);
      }
    },
    '$route.params.viewType': function (value) {
      this.viewType = value;
    },
    '$route.params': async function (data) {
      const instanceId = data.instanceId ?? null;
      const templateId = data.templateId ?? this.menuItem?.templateId ?? null;

      this.instanceId = instanceId;
      await NewTemplatesActions.init(templateId, instanceId, true);
    },
    async downloadXLSX(xslx) {
      if (xslx.status && xslx.templateIdList.length && xslx.blueprintIdList.length) {
        await this.exportXls(xslx.templateIdList, xslx.blueprintIdList, xslx.columnsList, xslx.workbooksNames);
        NewTemplatesMutations.SET_DOWNLOAD_XLSX(false, [], []);
      }
    },
  },
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    async generatePDFAction() {
      NewTemplatesMutations.SET_DOWNLOAD_PDF(true, true);
    },
    async generatePDF() {
      const cssPaths = this.extractCSSPaths();
      const css = await this.fetchAllCSS(cssPaths);

      const htmlObjectContent = document.getElementById('pdf-content').cloneNode(true);
      const buttons = htmlObjectContent.querySelectorAll('[data-html2canvas-ignore]');
      buttons.forEach(el => el.remove());
      Array.from(htmlObjectContent.getElementsByTagName('table')).forEach(table => {
        this.deleteColumn(table);
      });
      await exportsApi.getPDF(`<style>${css}</style><body>${htmlObjectContent.outerHTML}</body>`, this.template.name);
    },
    deleteColumn(table) {
      // get headers
      const headers = Array.from(table.getElementsByTagName('tr')[0].getElementsByTagName('th'));

      // find column index
      const columnIndex = headers.findIndex(header => header.innerText === 'Actiuni');

      // use column index to delete cells
      if (columnIndex !== -1) {
        // -1 indicates that the item was not found
        Array.from(table.rows).forEach(row => {
          if (row.cells.length > columnIndex) {
            row.deleteCell(columnIndex);
          }
        });
      }
    },
    async fetchAllCSS(cssPaths) {
      const cssPromises = cssPaths.map(path => fetch(path).then(response => response.text()));
      const cssFilesContent = await Promise.all(cssPromises);
      return cssFilesContent.join('\n');
    },
    extractCSSPaths() {
      const links = document.querySelectorAll('link[rel="stylesheet"]');
      return Array.from(links).map(link => link.href);
    },

    openLinkSharingDrawer() {
      this.isLinkSharingVisible = true;
    },
    closeLinkSharingDrawer() {
      this.isLinkSharingVisible = false;
    },
    async exportXls(templateIdList, blueprintIdList, columnsList, workbooksNames) {
      this.loading = true;
      const query = _.cloneDeep(NewTemplatesGetters.getLastQuery(templateIdList[0], blueprintIdList[0]));
      query.limit = null;
      const filterConditions = JSON.stringify(await FilterConditions(query.filterConditions));
      const queryStripParams = { ...query };
      const openInstance = NewTemplatesGetters.getMainInstance() ? JSON.stringify(NewTemplatesGetters.getMainInstance()) : null;
      await exportsApi.getAllXlsx(
        blueprintIdList[0],
        JSON.stringify(queryStripParams),
        filterConditions,
        columnsList[0],
        workbooksNames[0],
        openInstance
      );
      this.loading = false;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  #element-to-convert {
    margin-left: 0 !important;
  }
}
</style>
