<template>
  <div>
    <FieldConditions
      v-if="blueprint && localEvent?.validators?.conditionings[0]?.conditions"
      v-model="localEvent.validators.conditionings[0].conditions"
      :useFieldManager="true"
      :fieldConditionsBlueprint="mainBlueprint"
      :fieldConditions="localEvent.validators.conditionings[0].conditions ?? []"
      :blueprints="blueprints"
      :selectedBlueprint="blueprint"
      :toFieldFromOtherBlueprint="true"
      :justReferenceFields="false"
      :autoFieldConditions="true"
      :mainBlueprint="blueprint"
      :mainSelectedBlueprint="blueprint"
      :cardView="true"
      :otherBlueprint="true"
      :marginTopFromParent="'20px'"
      :showConditionsNulls="true"
    ></FieldConditions>
    <!--      @setFieldConditionsData ="setFieldConditionsData"-->
    <div v-if="!fields">
      <br />
      <a-alert message="No fields found." banner />
    </div>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import FieldConditions from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/fieldConditions.vue';
import { WorkflowActions } from '@/apps/workflow/shared/workflow.store';

export default {
  name: 'workflowBuilderValidationIndex',
  components: {
    FieldConditions,
  },
  props: ['selectedWorkflow', 'eventList', 'event'],
  data() {
    return {
      fields: [],
      fieldsById: {},
      blueprint: null,
      refreshFields: false,
      refreshFieldChangedType: false,
      referenceFields: [],
      referenceFieldsKey: 1,
      blueprints: null,
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  async mounted() {
    await this.fetchBlueprint();
    await this.loadBlueprints();
    if (!this.localEvent.validators.conditionings.length) {
      this.localEvent.validators.conditionings.push({
        blueprintId: this.selectedBlueprintId,
        conditions: [],
      });
    }
  },
  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
    mainBlueprint() {
      if (this.blueprints) {
        return this.blueprints.find(bp => bp._id.toString() === this.blueprint._id.toString());
      }
      return {};
    },
  },

  methods: {
    async loadBlueprints() {
      this.blueprints = await blueprintApi.getAllWithFields();
    },

    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);
      this.blueprint = blueprint;
      this.fieldsById = fieldsById;
      this.fields = Object.values(fieldsById);
    },
  },
};
</script>
<style scoped>
.full-width-divider {
  margin-left: -10px !important;
  margin-right: -10px !important;
  width: auto !important;
}
.custom-margin-divider {
  margin: 15px 0 !important;
}
.custom-tree .ant-select-selection {
  border-radius: 0 4px 4px 0 !important;
}
</style>
