<template>
  <div class="mt-3">
    <a-row>
      <a-col span="24">
        <div class="mb-2">Click on the element below to copy the code and insert it in a template to add a button that triggers this dataflow:</div>

        <a-tooltip :mouseLeaveDelay="0" title="Click to copy">
          <a-tag style="cursor: pointer" @click="copyEmbedCodeToClipboard">
            {{ embedString }}
          </a-tag>
        </a-tooltip>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';

export default {
  props: ['selectedDataProvider'],
  name: 'DataProviderBuilderEmbed',
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    embedString() {
      return `<a-button type="primary" :loading="dataflowLoading" @click="runManualDataflow('${this.selectedDataProvider._id}')">RUN ACTION</a-button>`;
    },
  },
  methods: {
    copyEmbedCodeToClipboard() {
      navigator.clipboard.writeText(this.embedString);

      message.success('Embed code copied to clipboard', 1);
    },
  },
  components: {},
};
</script>
<style scoped></style>
