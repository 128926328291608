<template>
  <div style="padding: 50px">
    <span style="font-weight: 500">API Key:</span>
    <div v-if="apiKeyVisible">
      <input :value="apiKey" readonly style="margin-top: 15px; width: 100%" @focus="$event.target.select()" />
      <a-button @click="hideApiKey" style="margin-top: 15px">Hide key</a-button>
    </div>
    <a-button @click="showApiKey" style="margin-left: 15px" v-else>Show key</a-button>
  </div>
</template>

<script>
import { webhookAuthApi } from '../../api';

export default {
  data() {
    return {
      apiKey: null,
      apiKeyVisible: false,
    };
  },
  methods: {
    async showApiKey() {
      this.apiKeyVisible = true;
      const auth = await webhookAuthApi.getOne();
      this.apiKey = auth.secretKey;
    },
    hideApiKey() {
      this.apiKeyVisible = false;
      this.apiKey = null;
    },
  },
};
</script>

<style></style>
