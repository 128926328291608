import LoginComponent from './views/Login/Login.vue';

const authRoutes = [
  {
    path: '/auth/login/:email?',
    name: 'LoginComponent',
    props: true,
    component: LoginComponent,
    meta: { isPublicPage: true },
  },
];

export default authRoutes;
