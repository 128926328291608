<template>
  <div class="Register_Container">
    <a-card style="width: 50%; margin: 25px auto; padding: 35px 50px">
      <h1>Register</h1>
      <div>
        <label class="Register_Label">E-mail address</label>
        <a-input :value="invitation.recipientEmail" :disabled="true" />
      </div>
      <div>
        <label class="Register_Label">First name</label>
        <a-input v-model:value="input.firstName" />
      </div>
      <div>
        <label class="Register_Label">Last name</label>
        <a-input v-model:value="input.lastName" />
      </div>
      <div>
        <label class="Register_Label">Password</label>
        <a-input v-model:value="input.password" type="password" />
      </div>
      <div>
        <label class="Register_Label">Confirm password</label>
        <a-input v-model:value="input.passwordConfirm" type="password" />
      </div>
      <div style="margin-top: 25px">
        <a-button @click="onClickRegister" type="primary" size="large"> Register </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import { userApi } from '@userManagement/api';
import { message } from 'ant-design-vue';

export default {
  props: ['invitation', 'invitationToken'],
  data() {
    return {
      input: {
        firstName: null,
        lastName: null,
        password: null,
        passwordConfirm: null,
      },
    };
  },
  methods: {
    async onClickRegister() {
      if (Object.values(this.input).some(value => !value || value.trim().length === 0)) {
        message.warning('All fields are required!');
        return;
      }

      if (this.input.password.length < 8) {
        message.warning('Password should have at least 8 characters!');
      }

      if (this.input.password !== this.input.passwordConfirm) {
        message.warning('Passwords do not match!');
        return;
      }

      try {
        await userApi.postRegister({
          ...this.input,
          email: this.invitation.recipientEmail,
          invitationToken: this.invitationToken,
        });
        message.success('Registered successfully!');
        this.$router.push({
          name: 'Login',
          params: { email: this.invitation.recipientEmail },
        });
      } catch (error) {
        this.error = true;
        message.error('Something went wrong...');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Register {
  &_Container {
    margin-top: -51px;
    padding-top: 51px;
    background-color: #f5f5f5;
    height: 100vh;
  }
  &_Label {
    display: block;
    margin-top: 20px;
  }
  &_RegisterButton {
    margin-top: 25px;
    width: 150px;
  }
}
</style>
