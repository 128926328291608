<template>
  <div>
    Tip element: {{ template.object?.view?.type }}<br />

    <div class="elementButtons">
      <a-tooltip title="Console log element"
        ><a-button size="small" @click="consoleConfig"><OrderedListOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Delete element"
        ><a-button @click="deleteElement" size="small"><DeleteOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Configurare setari element"
        ><a-button @click="modalSetariElementVisible = true" size="small"><SettingOutlined /> </a-button
      ></a-tooltip>
    </div>

    <a-modal v-model:open="modalSetariElementVisible" title="Configurare setari element" :width="1000">
      <div>
        <a-row>
          <a-col :span="12" style="padding-right: 20px">
            <a-checkbox v-model:checked="localTemplate.object.view.default.showTitle"> Show title </a-checkbox>
            <div></div>
            Custom title<br />
            <a-input v-model:value="localTemplate.object.view.default.customTitle" />
          </a-col>
        </a-row>
      </div>

      <hr />

      <div style="margin-top: 20px">
        <a-row>
          <a-col :span="24" class="mb-3">
            Html template before<br />
            <Codemirror
              v-model="localTemplate.object.view.contentHtml"
              :options="{
                btabSize: 4,
                mode: 'text/html',
                theme: 'base16-dark',
                lineNumbers: true,
                line: true,
              }"
              :extensions="extensions"
            />
          </a-col>
          <a-col :span="24" class="mb-3">
            Html template after<br />
            <Codemirror
              v-model="localTemplate.object.view.contentHtmlAfter"
              :options="{
                btabSize: 4,
                mode: 'text/html',
                theme: 'base16-dark',
                lineNumbers: true,
                line: true,
              }"
              :extensions="extensions"
            />
          </a-col>
        </a-row>
      </div>

      <hr />

      <a-row v-if="localTemplate.object.view?.contentHtmlSettings">
        <a-col :span="8">
          <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.idToLabel">
            idToLabel {{ template.object.view.contentHtmlSettings.idToLabel }}
          </a-checkbox>
        </a-col>

        <a-col :span="8">
          <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.fieldReferenceData"> fieldReferenceData </a-checkbox>
        </a-col>

        <a-col :span="8">
          <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.excludeFieldMultipleReferenceData">
            excludeFieldMultipleReferenceData
          </a-checkbox>
        </a-col>
      </a-row>

      <template #footer>
        <a-button key="submit" type="primary" @click="modalSetariElementVisible = false"> Ok </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import { DeleteOutlined, OrderedListOutlined, SettingOutlined } from '@ant-design/icons-vue';

export default {
  name: 'HtmlElementConfiguration',
  props: ['template'],
  emits: ['deleteElement'],
  components: {
    Codemirror,
    DeleteOutlined,
    OrderedListOutlined,
    SettingOutlined,
  },
  data() {
    return {
      modalSetariElementVisible: false,
      localTemplate: this.template ?? null,
      extensions: null,
    };
  },
  mounted() {
    this.localTemplate = this.template ?? null;
    const extensions = [javascript(), oneDark];
    this.extensions = extensions;
  },
  watch: {
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    consoleConfig() {
      console.log(this.template);
    },
    deleteElement() {
      this.$emit('deleteElement');
    },
  },
};
</script>

<style scoped></style>
