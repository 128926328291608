import { ApplicationRoleManager } from './views/ApplicationRoleManager';

const roleApplicationRoutes = [
  {
    path: '/list',
    name: 'ApplicationRoleManager',
    meta: {
      menuTitle: 'Roles',
    },
    component: ApplicationRoleManager,
  },
];

export default roleApplicationRoutes;
