<template>
  <div>
    <div v-if="typeof tenantSlug === 'undefined' && typeof appSlug === 'undefined'">
      <p>
        <a-checkbox v-model:checked="isGlobal" @change="saveBusinessObject">Global</a-checkbox>
      </p>
      <p v-if="!isGlobal">
        Apps usage:
        <a-tree-select
          v-if="treeDataApp"
          v-model:value="selectedAppData"
          show-search
          multiple
          allow-clear
          tree-checkable
          tree-default-expand-all
          :show-checked-strategy="SHOW_ALL"
          :tree-data="treeDataApp"
          style="width: 100%"
          placeholder="App select"
          :fieldNames="{
            key: '_id',
            value: '_id',
            children: 'applications',
            label: 'name',
          }"
        >
        </a-tree-select>
      </p>
    </div>

    <div v-if="typeof tenantSlug !== 'undefined' && typeof appSlug !== 'undefined'">
      <p v-if="!isGlobal">
        <a-checkbox v-model:checked="addToApp">Add to app</a-checkbox>
      </p>
      <p v-else><strong>Global</strong></p>
    </div>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue';
import { tenantApi } from '@tenants/api';
import { blueprintApi } from '@dataSystem/api';
import { SlugGetters } from '@/slug.store';

export default {
  data() {
    return {
      addToApp: false,
      SHOW_ALL: TreeSelect.SHOW_ALL,
      selectedAppData: this.input.applicationIdList ?? undefined,
      isGlobal: this.input.isGlobal ?? false,
      treeDataApp: null,
      treeDataSubtenant: null,
      isLoaded: false,
      // lastSavedInput:null,
    };
  },
  props: ['input', 'tenantsAndApps'],
  emits: ['updateInput'],

  async mounted() {
    await this.getTreeData();
    this.isLoaded = true;
  },
  computed: {
    tenantSlug: SlugGetters.getTenantSlug,
    appSlug: SlugGetters.getAppSlug,
  },
  watch: {
    async selectedAppData() {
      const localInput = this.input;
      if (!localInput.applicationIdList) {
        localInput.applicationIdList = [];
      }
      localInput.applicationIdList = this.selectedAppData;
      if (localInput._id) {
        await blueprintApi.patchOne(localInput._id, localInput);
      }
      this.$emit('updateInput', localInput);
    },

    async addToApp() {
      const localInput = this.input;
      if (this.isLoaded) {
        const currentAppId = this.getCurrentAppId();
        if (!this.input.applicationIdList) {
          localInput.applicationIdList = [];
          this.$emit('updateInput', localInput);
        }
        if (this.addToApp) {
          localInput.applicationIdList.push(currentAppId);
          if (localInput._id) {
            await blueprintApi.patchOne(localInput._id, localInput);
          }
          this.$emit('updateInput', localInput);
        } else {
          /* eslint-disable-next-line no-unused-vars */
          this.selectedAppData.forEach(appID => {
            this.selectedAppData = this.selectedAppData.filter(appId => appId !== currentAppId);
          });
        }
      }
    },
  },
  methods: {
    async saveBusinessObject() {
      if (this.isLoaded) {
        const localInput = this.input;
        localInput.isGlobal = this.isGlobal;
        this.$emit('updateInput', localInput);
        if (localInput._id) {
          await blueprintApi.patchOne(localInput._id, localInput);
        }
      }
    },
    getCurrentAppId() {
      const tenantKeys = Object.keys(this.tenantsAndApps);
      for (let index = 0; index < tenantKeys?.length; index += 1) {
        const app = this.tenantsAndApps[index].applications.find(ap => ap.slug === this.appSlug);
        if (app) {
          return app._id;
        }
      }
      return null;
    },
    async getTreeData() {
      if (!this.tenantsAndApps) {
        this.treeDataApp = await tenantApi.getAll();
        this.treeDataApp.forEach(tenant => {
          tenant.disabled = true;
        });
      } else {
        this.treeDataApp = this.tenantsAndApps;
      }

      if (this.treeDataApp) {
        this.treeDataSubtenant = Object.values(this.treeDataApp)
          .map(o => o.applications)
          .flat(1)
          .map(obj => ({ ...obj, disabled: true }));
      }
      if (this.input.applicationIdList) {
        if (this.input.applicationIdList.find(element => element === this.getCurrentAppId())) {
          this.addToApp = true;
        }
      }
    },
  },
};
</script>

<style></style>
