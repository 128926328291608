<template>
  <div style="position: relative">
    <div v-if="loading">
      <loading-spinner></loading-spinner>
    </div>
    <div v-else>
      <div class="mb-1">
        <span>To:</span>
        <a-input placeholder="To" v-model:value="to" />
      </div>

      <div class="mb-3">
        <div style="display: flex; align-items: center">
          Reply to:
          <a-tooltip
            :mouseLeaveDelay="0"
            title="Adresa de email la care clientul va trimite raspunsul folosind functionalitatea de 'Reply' din email"
          >
            <InfoCircleOutlined class="ml-2" style="color: #0000cc; cursor: help" />
          </a-tooltip>
        </div>
        <a-input placeholder="Reply to" v-model:value="replyTo" />
      </div>

      <div class="mb-2">
        <span>Subiect:</span>
        <a-input v-model:value="subject" placeholder="Subject" />
      </div>
      <div class="mb-3">
        <span>Mesaj:</span>
        <InputEditor v-model="content" :height="400"></InputEditor>
      </div>

      <a-button type="primary" @click="sendEmail" :loading="sendEmailLoading" :disabled="(disableSendMail || disableBySMTP) && disableBySMTP"
        >Send</a-button
      >
      <a-alert :message="'Serviciul de E-mail nu este configurat !'" v-if="disableBySMTP" :type="'error'" style="margin-top: 10px" />
    </div>
  </div>
</template>

<script>
import InputEditor from '@dataSystem/components/FieldWidget/Inputs/InputEditor';
import blueprintApi from '@dataSystem/api/blueprint.api';
import { instanceApi } from '@dataSystem/api';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { TenantsGetters } from '@/apps/tenants/shared/tenants.store';
import { SlugGetters } from '@/slug.store';

export default {
  components: {
    InputEditor,
    InfoCircleOutlined,
  },
  name: 'CreateEditInstance',
  props: ['object', 'dataColumns', 'rowsSelected', 'blueprint', 'instance', 'itemFields', 'listFields'],
  emits: ['clearSelections', 'closePopup'],
  data() {
    return {
      loading: false,
      sendEmailLoading: false,
      to: '',
      replyTo: '',
      subject: '',
      content: '',
      disableSendMail: true,
      disableBySMTP: true,
      subtenant: '',
    };
  },
  async mounted() {
    this.loading = true;
    this.subtenant = TenantsGetters.getSubtenantBySlugs(SlugGetters.getTenantSlug(), SlugGetters.getAppSlug(), SlugGetters.getSubtenantSlug());
    const emailSMTP = this.subtenant?.emailSMTP;
    if (emailSMTP?.server && emailSMTP?.port && emailSMTP?.requiresAuth !== null && emailSMTP?.username && emailSMTP?.password && emailSMTP?.email) {
      this.disableBySMTP = false;
    }
    const instancesIdListNotFoundEmail = [];
    // Funcția pentru procesarea coloanelor
    const processColumns = (columns, dataIndexKey) => {
      (columns ?? []).forEach(column => {
        if (column.widgetType === 'email') {
          this.rowsSelected.forEach(row => {
            const email = row[column[dataIndexKey]];
            if (email !== undefined) {
              if (email !== '' && email) {
                this.to += `${email};`;
              }
            } else {
              instancesIdListNotFoundEmail.push(row._id);
            }
          });
        }
      });
    };

    if (this.dataColumns && this.dataColumns.some(obj => obj.widgetType === 'email')) {
      processColumns(this.dataColumns, 'dataIndex');
    } else {
      processColumns(this.listFields, 'id');
    }

    // Verificăm dacă există instanțe fără email și facem solicitarea API
    if (instancesIdListNotFoundEmail.length > 0) {
      const emailColumns = this.listFields.filter(column => column.widgetType === 'email');

      await Promise.all(
        emailColumns.map(async column => {
          const queryStripParams = {
            instanceIdList: instancesIdListNotFoundEmail,
            instanceFieldList: [column.id],
          };
          const response = await instanceApi.getAll(this.blueprint._id, queryStripParams);
          response.instanceList.forEach(instance => {
            if (instance[column.id] !== '' && instance[column.id]) {
              this.to += `${instance[column.id]};`;
            }
          });
        })
      );
    }

    // Procesăm câmpurile elementelor dacă există
    if (this.itemFields) {
      this.itemFields.forEach(column => {
        if (column.widgetType === 'email' && this.instance[column.id] !== '' && this.instance[column.id]) {
          this.to += `${this.instance[column.id]};`;
        }
      });
    }

    this.loading = false;
  },
  methods: {
    async sendEmail() {
      this.sendEmailLoading = true;
      const emailList = this.to.split(';').filter(r => r !== '');
      await blueprintApi
        .massEmail(this.blueprint._id, {
          to: emailList,
          subject: this.subject,
          replyTo: this.replyTo,
          content: this.content,
        })
        .then(() => {
          notification.success({
            message: 'E-mails sent',
            description: 'All e-mails sent',
          });
          this.$emit('clearSelections');
          this.$emit('closePopup');
        })
        .catch(error => {
          console.log(error);
        });
      this.sendEmailLoading = false;
    },
  },

  watch: {
    to() {
      if (this.to !== '') {
        const emails = this.to.replace(/\s/g, '').split(';');
        this.disableSendMail = !emails.every(
          email => email === '' || email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
        );
      } else {
        this.disableSendMail = true;
      }
    },
    replyTo() {
      if (this.replyTo !== '') {
        const emails = this.replyTo.replace(/\s/g, '').split(';');
        this.disableSendMail = !emails.every(
          email => email === '' || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
        );
      } else {
        this.disableSendMail = true;
      }
    },
  },
};
</script>
