<template>
  <div>
    <BlueprintCreateModal :visible="isBlueprintCreateModalVisible" @created="onBlueprintCreated" @cancel="hideBlueprintCreateModal" />
    <template v-if="builderDrawer.isVisible">
      <a-drawer :open="builderDrawer.isVisible" @close="builderDrawer.isVisible = false" width="1200">
        <iframe :src="selectedBlueprintIframeUrl" frameBorder="0" style="height: 95vh; width: 98%" />
      </a-drawer>
    </template>
    <label>Select a Business Object to Reference</label>
    <a-select
      show-search
      placeholder="Choose Blueprint"
      option-filter-prop="blueprint-name"
      style="width: 100%; display: block; margin-top: 5px; margin-bottom: 15px"
      v-model:value="input.blueprintId"
    >
      <!--    v-if="menu adaugat pentru  -->
      <template #dropdownRender="menu" v-if="menu">
        <div>
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0" />
          <div style="padding: 4px 8px; cursor: pointer" @mousedown="e => e.preventDefault()" @click="onClickCreateBlueprint">
            <PlusOutlined />
            Create new Business Object
          </div>
        </div>
      </template>
      <a-select-option v-for="blueprint in blueprintList" :key="blueprint._id" :value="blueprint._id" :blueprint-name="blueprint.name">
        {{ blueprint.name }}
      </a-select-option>
    </a-select>
    <a-button v-if="input.blueprintId" @click="onClickEditBlueprintForm" style="margin-top: 15px">
      Open Form Builder for {{ selectedBlueprintName }}
    </a-button>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';

import { BlueprintCreateModal } from '@dataSystem/components/BlueprintCreateModal';

import { SlugGetters } from '@/slug.store';
import { PlusOutlined } from '@ant-design/icons-vue';

export default {
  emits: ['update:modelValue'],
  components: {
    BlueprintCreateModal,
    PlusOutlined,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      isBlueprintCreateModalVisible: false,
      blueprintList: [],
      menu: null,
      builderDrawer: {
        isVisible: false,
      },
      input: {
        blueprintId: null,
      },
    };
  },
  computed: {
    selectedBlueprint() {
      return this.blueprintList.find(b => b._id === this.input.blueprintId);
    },
    selectedBlueprintName() {
      return this.selectedBlueprint ? this.selectedBlueprint.name : null;
    },
    selectedBlueprintIframeUrl() {
      if (!this.selectedBlueprint) {
        return null;
      }
      console.log('SlugGetters.getAppUrl()', SlugGetters.getAppUrl());
      if (SlugGetters.getAppUrl()) {
        return `${SlugGetters.getAppUrl()}/dataSystem/blueprint/${this.input.blueprintId}/form-builder?no-back=1&no-nav=1&no-feedback=1`;
      }
      return `/tenants/blueprint/${this.input.blueprintId}/form-builder?no-back=1&no-nav=1&no-feedback=1`;
    },
  },
  mounted() {
    this.fetchBlueprintList();
  },
  methods: {
    onClickEditBlueprintForm() {
      this.builderDrawer.isVisible = true;
    },
    async fetchBlueprintList() {
      this.blueprintList = await blueprintApi.getAll();
    },
    onClickCreateBlueprint() {
      this.isBlueprintCreateModalVisible = true;
    },
    async onBlueprintCreated(newBlueprint) {
      this.blueprintList = await blueprintApi.getAll();
      this.input.blueprintId = newBlueprint._id;
      this.hideBlueprintCreateModal();
    },
    hideBlueprintCreateModal() {
      this.isBlueprintCreateModalVisible = false;
    },
  },
  watch: {
    input: {
      deep: true,
      handler() {
        if (this.input && this.input.blueprintId) {
          this.$emit('update:modelValue', this.input);
        } else {
          this.$emit('update:modelValue', null);
        }
      },
    },
  },
};
</script>
