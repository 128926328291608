export default {
  install() {
    if (process.env.VUE_APP_CLARITY_KEY) {
      (function (c, l, a, r, i) {
        // Ensure the clarity function exists
        c[a] =
          c[a] ||
          function (...args) {
            (c[a].q = c[a].q || []).push(args);
          };

        // Create and configure the script element
        const scriptElement = l.createElement(r);
        scriptElement.async = true;
        scriptElement.src = `https://www.clarity.ms/tag/${i}`;
        scriptElement.crossOrigin = 'anonymous'; // Ensure proper CORS settings

        // Insert the script element into the document
        const firstScript = l.getElementsByTagName(r)[0];
        firstScript.parentNode.insertBefore(scriptElement, firstScript);
      })(window, document, 'clarity', 'script', process.env.VUE_APP_CLARITY_KEY);

      // Optional: Adding custom tags
      window.clarity =
        window.clarity ||
        function (...args) {
          (window.clarity.q = window.clarity.q || []).push(args);
        };
    }
  },
};
