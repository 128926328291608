<template>
  <a-timeline class="actions__timeline">
    <h2 style="margin-bottom: 18px">Roles</h2>
    <a-timeline-item
      v-for="role in roles"
      :key="role._id"
      :color="hoveredRoleId === role._id || selectedRoleId === role._id ? 'blue' : 'gray'"
      :style="hoveredRoleId === role._id || selectedRoleId === role._id ? 'font-weight: 500' : ''"
      @click="() => selectRole(role._id)"
      @mouseover="hoveredRoleId = role._id"
      @mouseleave="hoveredRoleId = null"
    >
      <template #dot>
        <TeamOutlined />
      </template>
      {{ role.name }}
      <template v-if="hoveredRoleId === role._id || selectedRoleIdToRemove === role._id">
        <PopconfirmButtonDelete
          :iconOnly="true"
          entityName="Role"
          style="float: right"
          @cancel="selectedRoleIdToRemove = null"
          @click="selectedRoleIdToRemove = role._id"
          @confirm="() => deleteRole(role._id)"
          ><CloseOutlined
        /></PopconfirmButtonDelete>
      </template>
    </a-timeline-item>
    <a-timeline-item v-if="isAddingNewRole">
      <template #dot>
        <TeamOutlined />
      </template>
      <a-input
        size="small"
        id="newRoleName"
        ref="newRoleNameInput"
        v-model:value="newRoleName"
        placeholder="New role name"
        allow-clear
        @keyup.enter="handleRoleCreate"
        @blur="handleRoleCreate"
      ></a-input>
    </a-timeline-item>
    <a-timeline-item>
      <template #dot>
        <UsergroupAddOutlined />
      </template>
      <a @click="openRoleCreate">Add role</a>
    </a-timeline-item>
  </a-timeline>
</template>

<script>
import PopconfirmButtonDelete from '@/core/components/PopconfirmButtonDelete.vue';
import { CloseOutlined, TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons-vue';

export default {
  props: ['roles'],
  emits: ['select', 'create', 'remove'],
  data() {
    return {
      hoveredRoleId: null,
      selectedRoleId: null,
      isAddingNewRole: false,
      newRoleName: '',
      selectedRoleIdToRemove: null,
    };
  },
  components: {
    PopconfirmButtonDelete,
    TeamOutlined,
    UsergroupAddOutlined,
    CloseOutlined,
  },
  methods: {
    selectRole(roleId) {
      this.selectedRoleId = roleId;
      this.$emit('select', this.selectedRoleId);
    },
    openRoleCreate() {
      this.isAddingNewRole = true;
      this.$nextTick(() => {
        this.$refs.newRoleNameInput.focus();
      });
    },
    handleRoleCreate() {
      if (this.newRoleName && this.newRoleName.length > 0) {
        this.$emit('create', this.newRoleName);
      }
      this.closeRoleCreate();
    },
    closeRoleCreate() {
      this.isAddingNewRole = false;
      this.newRoleName = '';
    },
    deleteRole(roleId) {
      this.$emit('remove', roleId);
    },
  },
};
</script>

<style scoped>
.actions__timeline :deep(.ant-timeline-item-content) {
  cursor: pointer;
}
</style>
