<template>
  <div>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
      <a-spin />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import workflowApi from '../../../workflow.api';

export default {
  data() {
    return {
      workflow: {
        name: 'Workflow group',
        workflows: [
          {
            name: 'Workflow name',
            activ: true,
          },
        ],
      },
    };
  },
  created() {
    this.addWorkflow();
  },
  methods: {
    async addWorkflow() {
      try {
        const newWorkflow = await workflowApi.postOneWorkflowGroup(this.workflow);
        await router.push({
          name: 'WorkflowBuilder',
          params: { workflowGroupId: newWorkflow._id },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
