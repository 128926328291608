<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="item-container"
    :list="realValue"
    ghost-class="ghost"
    :modelValue="modelValue"
    @end="listChange"
    @input="emitter"
    item-key="_id"
  >
    <template #item="{ element, index }">
      <div :class="'item-group'">
        <div
          :class="'item' + (!element.active ? ' inactive' : '') + (!element.showInMenu ? ' hide' : '')"
          @click="$emit('select', element, depth ?? 0, index)"
        >
          {{ element.title }}
          <!--        <span style="font-size: 12px">({{item.subtenantId.length}} subtenants)</span>-->
          <LinkOutlined v-if="element.templateId" style="position: relative; top: -4px; margin-left: 7px" />
        </div>
        <nested-menu
          :style="!element.active ? 'opacity: 0.4' : ''"
          @listChange="listChange"
          class="item-sub"
          :list="element.children"
          :depth="depth + 1"
          @select="(el, d, i) => $emit('select', el, d, i)"
        />
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import { LinkOutlined } from '@ant-design/icons-vue';

export default {
  name: 'nested-menu',
  emits: ['update:modelValue', 'input', 'select', 'listChange'],
  props: {
    depth: {
      type: Number,
      default: 0,
    },
    modelValue: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
  components: {
    draggable,
    LinkOutlined,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    realValue() {
      return this.modelValue ? this.modelValue : this.list;
    },
  },
  methods: {
    emitter(value) {
      this.$emit('update:modelValue', value);
    },

    listChange() {
      this.$emit('listChange', this.modelValue);
    },
  },
};
</script>
<style scoped>
.item-container {
  margin: 0;
}

.item {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 5px;
  cursor: pointer;
}
.item-sub {
  margin: 0 0 0 1rem;
}

.ghost {
  opacity: 0.4;
  background: #ffffff;
  border-radius: 4px;
}

.inactive {
  background-color: rgba(155, 94, 94, 0.1);
  border-color: #e09393;
}

.hide {
  opacity: 0.5;
}
</style>
