import axios from 'axios';

const getAll = async (options = {}) => {
  const params = options ? { ...options } : null;

  try {
    const response = await axios.get(`/tenant/application/subtenants`, {
      params,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const postOne = async (tenantId, appId, data) => {
  try {
    const response = await axios.post(`/tenant/${tenantId}/application/${appId}/subtenant`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const putOne = async (tenanatId, applicationId, subtenantId, subtenantInput) => {
  try {
    const response = await axios.put(`/tenant/${tenanatId}/application/${applicationId}/subtenant/${subtenantId}`, subtenantInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteOne = async (tenanatId, applicationId, subtenantId) => {
  try {
    await axios.delete(`/tenant/${tenanatId}/application/${applicationId}/subtenant/${subtenantId}`);
    return true;
  } catch (error) {
    return null;
  }
};

const postManager = async (tenanatId, appId, subtenantId, managerInput) => {
  try {
    const response = await axios.post(`/tenant/${tenanatId}/application/${appId}/subtenant/${subtenantId}/manager`, managerInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const putManagerAccess = async (tenanatId, appId, subtenantId, userId, managerInput) => {
  try {
    const response = await axios.put(`/tenant/${tenanatId}/application/${appId}/subtenant/${subtenantId}/manager/${userId}/expiration`, managerInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteManager = async (tenantId, appId, subtenantId, userId) => {
  try {
    const response = await axios.delete(`/tenant/${tenantId}/application/${appId}/subtenant/${subtenantId}/manager/${userId}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteUser = async (tenantId, appId, subtenantId, userId) => {
  const response = await axios.delete(`/tenant/${tenantId}/application/${appId}/subtenant/${subtenantId}/user/${userId}`);
  return response.data;
};

export default {
  getAll,
  postOne,
  putOne,
  deleteOne,
  deleteUser,
  deleteManager,

  postManager,
  putManagerAccess,
};
