import axios from 'axios';

const postOne = async (tenantId, data) => {
  try {
    const response = await axios.post(`/tenant/${tenantId}/application`, {
      data,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const putOne = async (tenanatId, applicationId, applicationInput) => {
  try {
    const response = await axios.put(`/tenant/${tenanatId}/application/${applicationId}`, applicationInput);
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data.error.message,
    };
  }
};

const deleteOne = async (tenanatId, applicationId) => {
  try {
    await axios.delete(`/tenant/${tenanatId}/application/${applicationId}`);
    return true;
  } catch (error) {
    return null;
  }
};

export default {
  postOne,
  putOne,
  deleteOne,
};
