import axios from 'axios';

import { SlugGetters } from '@/slug.store';
// eslint-disable-next-line import/no-cycle
import { NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';

const { getSubtenantUrl, getFullUrlForSubtenant, getAppUrlWithGlobal } = SlugGetters;

const createFormDataObject = instanceInput => {
  const formData = new FormData();
  Object.keys(instanceInput).forEach(prop => {
    if (typeof instanceInput[prop] === 'object' && instanceInput[prop]?.type === 'file') {
      if (Array.isArray(instanceInput[prop]?.fileObjects) && Array.isArray(instanceInput[prop]?.fileData)) {
        instanceInput[prop]?.fileObjects.forEach(file => {
          // add file to FormData object
          formData.append(prop, file);
        });

        formData.append(prop, JSON.stringify(instanceInput[prop]?.fileData));
      }
    } else {
      formData.append(prop, JSON.stringify(instanceInput[prop]));
    }
  });

  return formData;
};

const getAll = async (blueprintId, params, filterConditions, openInstance = null) => {
  const response = await axios.post(`${getSubtenantUrl()}/data-system/instances/all?blueprintId=${blueprintId}`, {
    params,
    filterConditions,
    openInstance,
  });
  return response.data;
};

const getAllForSubtenant = async (blueprintId, subtenantSlug, params, filterConditions) => {
  const response = await axios.post(`${getFullUrlForSubtenant(subtenantSlug)}/data-system/instances/all?blueprintId=${blueprintId}`, {
    params,
    filterConditions,
  });
  return response.data;
};

const getOne = async (blueprintId, instanceId, data = {}, subtenantSlug = null) => {
  let url;
  if (subtenantSlug) {
    url = `${getAppUrlWithGlobal()}/${subtenantSlug}/data-system/instances/${instanceId}?blueprintId=${blueprintId}`;
  } else {
    url = `${getAppUrlWithGlobal()}/data-system/instances/${instanceId}?blueprintId=${blueprintId}`;
  }
  const response = await axios.post(url, data);
  return response.data;
};

const getSubtenantData = async (subtenantId, subtenantSlug = null) => {
  let url;
  if (subtenantSlug) {
    url = `${getAppUrlWithGlobal()}/${subtenantSlug}/data-system/instances/subtenant-data/${subtenantId}`;
  } else {
    url = `${getAppUrlWithGlobal()}/data-system/instances/subtenant-data/${subtenantId}`;
  }
  const response = await axios.post(url);
  return response.data;
};

const getFiledUniqueValues = async (blueprintId, fieldId) => {
  const response = await axios.get(`${getSubtenantUrl()}/data-system/instances/filed-unique-values/${fieldId}?blueprintId=${blueprintId}`);
  return response.data;
};

const postOne = async (blueprintId, ownerRoleId, instanceInput, fields, subtenantSlug = null) => {
  const formData = createFormDataObject(instanceInput);
  const openInstance = NewTemplatesGetters.getMainInstance() ?? null;
  formData.append('openInstance', JSON.stringify(openInstance));
  let url;
  if (subtenantSlug) {
    url = `${getAppUrlWithGlobal()}/${subtenantSlug}/data-system/instances?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}`;
  } else {
    url = `${getAppUrlWithGlobal()}/data-system/instances?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}`;
  }
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const publicPostOne = async (blueprintId, ownerRoleId, instanceInput, reCaptchaToken) => {
  const response = await axios.post(
    `${getSubtenantUrl()}/data-system/instances?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}&reCaptchaToken=${reCaptchaToken}`,
    instanceInput
  );
  return response.data;
};

const patchOne = async (blueprintId, instanceId, ownerRoleId, instanceInput, subtenantSlug = null) => {
  const openInstance = NewTemplatesGetters.getMainInstance() ?? null;
  const formData = createFormDataObject(instanceInput);
  let url;
  if (subtenantSlug) {
    url = `${getAppUrlWithGlobal()}/${subtenantSlug}/data-system/instances/${instanceId}?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}`;
  } else {
    url = `${getAppUrlWithGlobal()}/data-system/instances/${instanceId}?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}`;
  }
  formData.append('openInstance', JSON.stringify(openInstance));
  const response = await axios.patch(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const patchMany = async (blueprintId, instanceId, ownerRoleId, instanceInput, subtenantSlug = null) => {
  const formData = createFormDataObject(instanceInput);
  let url;
  if (subtenantSlug) {
    url = `${getAppUrlWithGlobal()}/${subtenantSlug}/data-system/instances/multiple?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}`;
  } else {
    url = `${getAppUrlWithGlobal()}/data-system/instances/multiple?blueprintId=${blueprintId}&ownerRoleId=${ownerRoleId}`;
  }
  const response = await axios.patch(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const deleteOne = async (blueprintId, instanceId) => {
  const response = await axios.delete(`${getSubtenantUrl()}/data-system/instances/${instanceId}?blueprintId=${blueprintId}`);
  return response.data;
};

const sendSMS = async smsData => {
  const response = await axios.post(`${getSubtenantUrl()}/data-system/instances/send-sms`, { sms: smsData });
  return response.data;
};

export default {
  getAll,
  getAllForSubtenant,
  getOne,
  getFiledUniqueValues,
  postOne,
  patchOne,
  deleteOne,
  publicPostOne,
  patchMany,
  getSubtenantData,
  sendSMS,
};
