import { reactive } from 'vue';
import { dataAuthRest } from '@dataAuthRest/api';

const initialState = {
  dataAuthRestList: [],
};

const state = reactive({ ...initialState });

const Getters = {
  getDataAuthRestList: () => {
    return state.dataAuthRestList;
  },
};

const Mutations = {};

const Actions = {
  fetchList: async () => {
    state.dataAuthRestList = await dataAuthRest.getAll();
  },
  addNew: async (name, authRestId = null, addToList = true) => {
    const response = await dataAuthRest.postOne({
      name: name ?? 'New auth rest',
      description: '',
      mainRequest: authRestId === null,
      parentDataAuthRestId: authRestId,
    });
    if (addToList) {
      state.dataAuthRestList.push(response);
    }
    return response;
  },
  editOne: async (authRestId, data) => {
    const response = await dataAuthRest.editOne(authRestId, data);
    state.dataAuthRestList = state.dataAuthRestList.map(item => (item._id === authRestId ? item : response));
    return response;
  },
  test: async (authRestId, data) => {
    const response = await dataAuthRest.testEndpoint(authRestId, {
      ...data,
    });
    return response;
  },
  deleteOne: async authRestId => {
    const response = await dataAuthRest.deleteOne(authRestId);
    state.dataAuthRestList = state.dataAuthRestList.filter(item => item._id !== authRestId);
    return response;
  },
};

export const DataAuthRestGetters = Getters;
export const DataAuthRestMutations = Mutations;
export const DataAuthRestActions = Actions;
