import axios from 'axios';

const postLogin = async (email, password) => {
  const response = await axios.post(
    `/auth/login`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
};

const putLoginRefresh = async () => {
  const response = await axios.put(`/auth/login-refresh`, null, {
    withCredentials: true,
  });
  return response.data;
};

const deleteLogout = async () => {
  await axios({
    method: 'delete',
    url: '/auth/logout',
    withCredentials: true,
  });
};

export default {
  postLogin,
  putLoginRefresh,
  deleteLogout,
};
