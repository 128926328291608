import { DataAuthRestBuilder } from './views/Builder';
import { DataAuthRestList } from './views/List';

const dataAuthRest = [
  {
    path: '/',
    name: 'DataAuthRestList',
    meta: {
      menuTitle: 'External Auths',
    },
    component: DataAuthRestList,
  },
  {
    path: '/:authRestId',
    name: 'DataAuthRestBuilder',
    meta: {
      menuTitle: 'Rest auth',
    },
    component: DataAuthRestBuilder,
  },
];
export default dataAuthRest;
