import { reactive } from 'vue';

const initialState = {
  runningAudioPlayerSrc: null,
};

const state = reactive({ ...initialState });

const Getters = {
  getRunningAudioPlayerSrc() {
    return state.runningAudioPlayerSrc;
  },
};

const Mutations = {
  SET_UPDATE_AUDIO_PLAYER_SRC: src => {
    state.runningAudioPlayerSrc = src;
  },
};

const Actions = {
  async setRunningAudioPlayerSrc(src) {
    Mutations.SET_UPDATE_AUDIO_PLAYER_SRC(src);
  },
};

export const DataSystemGetters = Getters;
export const DataSystemMutations = Mutations;
export const DataSystemActions = Actions;
