import axios from 'axios';

import { SlugGetters } from '@/slug.store';

const { getAppUrl, getAppUrlWithGlobal } = SlugGetters;

const getAll = async () => {
  try {
    const response = await axios.get(`${getAppUrl()}/menu-builder/list`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postOne = async data => {
  const response = await axios.post(`${getAppUrl()}/menu-builder/add`, data);
  return response;
};

const putOne = async (menuId, data) => {
  const response = await axios.put(`${getAppUrl()}/menu-builder/edit/${menuId}`, data);
  return response;
};

const startPage = async (menuId, data) => {
  const response = await axios.put(`${getAppUrl()}/menu-builder/edit/start-page/${menuId}`, data);
  return response;
};

const deleteOne = async menuId => {
  const response = await axios.delete(`${getAppUrl()}/menu-builder/${menuId}`);
  return response;
};

const getLayout = async (withSubtenant = false) => {
  try {
    const response = await axios.get(`${getAppUrlWithGlobal(withSubtenant)}/menu-builder/layout`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const updateOneLayout = async data => {
  const response = await axios.post(`${getAppUrl()}/menu-builder/layout`, data);
  return response;
};

export default {
  getAll,
  postOne,
  putOne,
  startPage,
  deleteOne,

  getLayout,
  updateOneLayout,
};
