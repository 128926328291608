<template>
  <a-drawer title="Change Password" width="500px" :open="visible" @close="emitClose">
    <div style="margin-top: 20px">
      <label>Current password</label>
      <a-input v-model:value="input.currentPassword" type="password" />
    </div>
    <div style="margin-top: 20px">
      <label>New password</label>
      <a-input v-model:value="input.newPassword" type="password" />
    </div>
    <div style="margin-top: 20px">
      <label>Confirm new password</label>
      <a-input v-model:value="input.newPasswordConfirm" type="password" />
    </div>
    <div style="margin-top: 30px">
      <a-button @click="onClickSave" type="primary">Confirm and save</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { credentialApi } from '@userManagement/api';
import { message } from 'ant-design-vue';

export default {
  props: ['visible'],
  emits: ['close'],
  data() {
    return {
      input: {
        currentPassword: null,
        newPassword: null,
        newPasswordConfirm: null,
      },
    };
  },
  methods: {
    async updatePassword() {
      if (!this.input.currentPassword || this.input.currentPassword.trim() === '') {
        message.warning('Current password is required!');
        return;
      }
      if (!this.input.newPassword || this.input.newPassword.trim() === '') {
        message.warning('New password is required!');
        return;
      }
      if (!this.input.newPasswordConfirm || this.input.newPasswordConfirm.trim() === '') {
        message.warning('Please confirm the new password!');
        return;
      }
      if (this.input.newPassword.length < 8) {
        message.warning('The new password should have at least 8 characters.');
        return;
      }
      if (this.input.newPassword !== this.input.newPasswordConfirm) {
        message.warning("New password doesn't match the confirmation.");
        return;
      }

      try {
        await credentialApi.patchPasswordChange(this.input.currentPassword, this.input.newPassword);
        message.success('Password changed successfully!');
        this.emitClose();
      } catch {
        message.error('Something went wrong. The password did not change.');
      }
    },
    emitClose() {
      this.$emit('close');
    },
    async onClickSave() {
      await this.updatePassword();
    },
  },
};
</script>

<style></style>
