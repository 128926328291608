<template>
  <div>
    <template v-if="ConditionalAuthorityMode.visible">
      <ConditionalAuthorityMode
        :visible="ConditionalAuthorityMode.visible"
        :authority="ConditionalAuthorityMode.authority"
        :blueprintId="ConditionalAuthorityMode.blueprintId"
        @update="onUpdateConditionalAuthority"
        @close="onCloseConditionalAuthority"
      />
    </template>
    <pre>
      <!-- {{ tableData }}-->
    </pre>
    <a-table :columns="tableColumns" :data-source="tableData" :pagination="tablePagination" rowKey="blueprintId">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'blueprintId'">
          {{ blueprintById[record.blueprintId].name }}
        </template>

        <template v-if="column.key == 'createAuthorityMode'">
          <a-select
            :value="record.createAuthorityMode"
            @change="newValue => onCreateAuthorityModeChange(record.blueprintId, newValue)"
            style="width: 90%"
          >
            <a-select-option value="yes">Yes</a-select-option>
            <a-select-option value="no">No</a-select-option>
          </a-select>
        </template>

        <template v-if="column.key == 'viewAuthorityMode'">
          <a-select
            :value="record.viewAuthorityMode"
            @change="newValue => onViewAuthorityModeChange(record.blueprintId, newValue)"
            style="width: 90%"
          >
            <a-select-option value="all_instances">All instances</a-select-option>
            <a-select-option value="role_owned">Instances owned by role</a-select-option>
            <a-select-option value="user_owned">Instances owned by user</a-select-option>
            <a-select-option value="conditional">Custom rule</a-select-option>
            <a-select-option value="none">None</a-select-option>
          </a-select>
          <EditOutlined
            v-if="record.viewAuthorityMode === 'conditional'"
            @click="() => onEditViewConditionalAuthority(record.blueprintId)"
            type="edit"
            style="display: inline-block; margin-left: 15px; cursor: pointer"
          />
        </template>

        <template v-if="column.key == 'modifyAuthorityMode'">
          <a-select
            :value="record.modifyAuthorityMode"
            @change="newValue => onModifyAuthorityModeChange(record.blueprintId, newValue)"
            style="width: 90%"
          >
            <a-select-option value="all_instances">All instances</a-select-option>
            <a-select-option value="role_owned">Instances owned by role</a-select-option>
            <a-select-option value="user_owned">Instances owned by user</a-select-option>
            <a-select-option value="conditional">Custom rule</a-select-option>
            <a-select-option value="none">None</a-select-option>
          </a-select>
          <EditOutlined
            v-if="record.modifyAuthorityMode === 'conditional'"
            @click="() => onEditModifyConditionalAuthority(record.blueprintId)"
            type="edit"
            style="display: inline-block; margin-left: 15px; cursor: pointer"
          />
        </template>

        <template v-if="column.key == 'deleteAuthorityMode'">
          <a-select
            :value="record.deleteAuthorityMode"
            @change="newValue => onDeleteAuthorityModeChange(record.blueprintId, newValue)"
            style="width: 90%"
          >
            <a-select-option value="all_instances">All instances</a-select-option>
            <a-select-option value="role_owned">Instances owned by role</a-select-option>
            <a-select-option value="user_owned">Instances owned by user</a-select-option>
            <a-select-option value="conditional">Custom rule</a-select-option>
            <a-select-option value="none">None</a-select-option>
          </a-select>
          <EditOutlined
            v-if="record.deleteAuthorityMode === 'conditional'"
            @click="() => onEditDeleteConditionalAuthority(record.blueprintId)"
            type="edit"
            style="display: inline-block; margin-left: 15px; cursor: pointer"
          />
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { EditOutlined } from '@ant-design/icons-vue';
import ConditionalAuthorityMode from '@roleManagement/components/ConditionalAuthorityMode';
import { message } from 'ant-design-vue';

const tableColumns = [
  {
    title: 'Business Object',
    key: 'blueprintId',
    dataIndex: 'blueprintId',
  },
  {
    title: 'Can Create',
    key: 'createAuthorityMode',
    dataIndex: 'createAuthorityMode',
  },
  {
    title: 'Can View',
    key: 'viewAuthorityMode',
    dataIndex: 'viewAuthorityMode',
  },
  {
    title: 'Can Modify',
    key: 'modifyAuthorityMode',
    dataIndex: 'modifyAuthorityMode',
  },
  {
    title: 'Can Delete',
    key: 'deleteAuthorityMode',
    dataIndex: 'deleteAuthorityMode',
  },
];

export default {
  props: ['blueprintById', 'permissionByBlueprintId'],
  emits: ['update'],
  components: {
    ConditionalAuthorityMode,
    EditOutlined,
  },
  data() {
    return {
      ConditionalAuthorityMode: {
        visible: false,
        blueprintId: null,
        authority: null,
        authorityType: null,
      },
      tablePagination: {
        pageSize: 20,
      },
      tableColumns,
    };
  },
  computed: {
    tableData() {
      if (!this.permissionByBlueprintId) {
        return [];
      }
      return Object.entries(this.permissionByBlueprintId)
        .filter(([blueprintId]) => Object.keys(this.blueprintById).indexOf(blueprintId) !== -1)
        .map(([blueprintId, permission]) => {
          return {
            blueprintId,
            createAuthorityMode: permission.createAuthority.mode,
            viewAuthorityMode: permission.viewAuthority.mode,
            modifyAuthorityMode: permission.modifyAuthority.mode,
            deleteAuthorityMode: permission.deleteAuthority.mode,
          };
        });
    },
  },
  methods: {
    onCreateAuthorityModeChange(blueprintId, newValue) {
      const { createAuthority } = this.permissionByBlueprintId[blueprintId];

      this.$emit('update', {
        blueprintId,
        permissionInput: {
          ...this.permissionByBlueprintId[blueprintId],
          createAuthority: {
            ...createAuthority,
            mode: newValue,
          },
        },
      });
      message.success('Saved');
    },
    onDeleteAuthorityModeChange(blueprintId, newValue) {
      const { deleteAuthority } = this.permissionByBlueprintId[blueprintId];

      if (newValue === 'conditional') {
        this.onEditDeleteConditionalAuthority(blueprintId);
      }

      this.$emit('update', {
        blueprintId,
        permissionInput: {
          ...this.permissionByBlueprintId[blueprintId],
          deleteAuthority: {
            ...deleteAuthority,
            mode: newValue,
          },
        },
      });
      message.success('Saved');
    },
    onEditDeleteConditionalAuthority(blueprintId) {
      const { deleteAuthority } = this.permissionByBlueprintId[blueprintId];

      this.ConditionalAuthorityMode = {
        ...this.ConditionalAuthorityMode,
        blueprintId,
        authority: deleteAuthority,
        visible: true,
        authorityType: 'delete',
      };
    },

    onEditViewConditionalAuthority(blueprintId) {
      const { viewAuthority } = this.permissionByBlueprintId[blueprintId];

      this.ConditionalAuthorityMode = {
        ...this.ConditionalAuthorityMode,
        blueprintId,
        authority: viewAuthority,
        visible: true,
        authorityType: 'view',
      };
    },
    onEditModifyConditionalAuthority(blueprintId) {
      const { modifyAuthority } = this.permissionByBlueprintId[blueprintId];

      this.ConditionalAuthorityMode = {
        ...this.ConditionalAuthorityMode,
        blueprintId,
        authority: modifyAuthority,
        visible: true,
        authorityType: 'modify',
      };
    },
    onViewAuthorityModeChange(blueprintId, newValue) {
      const { viewAuthority } = this.permissionByBlueprintId[blueprintId];

      if (newValue === 'conditional') {
        this.onEditViewConditionalAuthority(blueprintId);
      }

      this.$emit('update', {
        blueprintId,
        permissionInput: {
          ...this.permissionByBlueprintId[blueprintId],
          viewAuthority: {
            ...viewAuthority,
            mode: newValue,
          },
        },
      });
      message.success('Saved');
    },
    onModifyAuthorityModeChange(blueprintId, newValue) {
      const { modifyAuthority } = this.permissionByBlueprintId[blueprintId];

      if (newValue === 'conditional') {
        this.onEditModifyConditionalAuthority(blueprintId);
      }

      this.$emit('update', {
        blueprintId,
        permissionInput: {
          ...this.permissionByBlueprintId[blueprintId],
          modifyAuthority: {
            ...modifyAuthority,
            mode: newValue,
          },
        },
      });
      message.success('Saved');
    },
    onUpdateConditionalAuthority(conditionalAuthority) {
      const { blueprintId, authorityType } = this.ConditionalAuthorityMode;

      const { viewAuthority } = this.permissionByBlueprintId[blueprintId];

      const { modifyAuthority } = this.permissionByBlueprintId[blueprintId];

      const { deleteAuthority } = this.permissionByBlueprintId[blueprintId];

      if (authorityType === 'view') {
        this.$emit('update', {
          blueprintId,
          permissionInput: {
            ...this.permissionByBlueprintId[blueprintId],
            viewAuthority: {
              ...viewAuthority,
              ...conditionalAuthority,
            },
          },
        });
      } else if (authorityType === 'modify') {
        this.$emit('update', {
          blueprintId,
          permissionInput: {
            ...this.permissionByBlueprintId[blueprintId],
            modifyAuthority: {
              ...modifyAuthority,
              ...conditionalAuthority,
            },
          },
        });
      } else if (authorityType === 'delete') {
        this.$emit('update', {
          blueprintId,
          permissionInput: {
            ...this.permissionByBlueprintId[blueprintId],
            deleteAuthority: {
              ...deleteAuthority,
              ...conditionalAuthority,
            },
          },
        });
      }
      message.success('Saved');
    },
    onCloseConditionalAuthority() {
      this.ConditionalAuthorityMode = {
        visible: false,
        blueprintId: null,
        authority: null,
        authorityType: null,
      };
    },
  },
};
</script>

<style></style>
