import axios from 'axios';

import LogRocket from 'logrocket';

import { authRequestInterceptor } from '@userManagement/auth.interceptor';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.response.use(...authRequestInterceptor);

LogRocket.getSessionURL(sessionURL => {
  axios.defaults.headers.common['X-LogRocket-URL'] = sessionURL;
});
