import { SubtenantRoleManager } from './views/SubtenantRoleManager';

const roleSubtenantRoutes = [
  {
    path: '/list',
    name: 'SubtenantRoleManager',
    meta: {
      menuTitle: 'Roles',
    },
    component: SubtenantRoleManager,
  },
];

export default roleSubtenantRoutes;
