import WorkflowBuilder from './views/WorkflowBuilder/WorkflowBuilder.vue';
import AddWorkflow from './views/WorkflowBuilder/components/AddWorkflow.vue';
import WorkflowList from './views/WorkflowList/WorkflowListBuilder.vue';

const workflowRoutes = [
  {
    path: '/add',
    name: 'AddWorkflow',
    component: AddWorkflow,
    meta: {
      menuTitle: 'Workflows',
    },
  },
  {
    path: '/list',
    name: 'WorkflowList',
    component: WorkflowList,
    meta: {
      menuTitle: 'Workflows',
    },
  },
  {
    path: '/search',
    name: 'WorkflowSearch',
    component: WorkflowList,
    meta: {
      menuTitle: 'Workflows',
    },
  },
  {
    path: '/search/:blueprintId',
    name: 'WorkflowSearchPath',
    component: WorkflowList,
    meta: {
      menuTitle: 'Workflows',
    },
  },
  {
    path: '/:workflowGroupId',
    name: 'WorkflowBuilder',
    component: WorkflowBuilder,
    meta: {
      menuTitle: 'Workflows',
    },
  },
  {
    path: '/:workflowGroupId/flow/:workflowId',
    name: 'SelectWorkflow',
    component: WorkflowBuilder,
    meta: {
      menuTitle: 'Workflows',
    },
  },
  {
    path: '/:workflowGroupId/flow/:workflowId/event/:eventId',
    name: 'SelectEvent',
    component: WorkflowBuilder,
    meta: {
      menuTitle: 'Workflows',
    },
  },
];

export default workflowRoutes;
