<template>
  <div>
    <a-timeline class="actions__timeline" style="margin: 0; margin-top: 24px">
      <h2 style="margin-bottom: 18px">{{ role.name }} users</h2>
      <a-timeline-item
        v-for="user in role.users"
        :key="user._id"
        :color="hoveredUserId === user._id ? 'blue' : 'gray'"
        @mouseover="hoveredUserId = user._id"
        @mouseleave="hoveredUserId = null"
      >
        <template #dot>
          <UserOutlined />
        </template>
        <span class="user-full-name"> {{ user.lastName }} {{ user.firstName }} </span>
        <template v-if="hoveredUserId === user._id || selectedUserIdToRemove === user._id">
          <PopconfirmButtonDelete
            :iconOnly="true"
            entityName="User"
            style="float: right"
            @cancel="selectedUserIdToRemove = null"
            @click="selectedUserIdToRemove = user._id"
            @confirm="() => removeUser(user._id)"
            ><CloseOutlined />
          </PopconfirmButtonDelete>
        </template>
      </a-timeline-item>
      <a-timeline-item>
        <template #dot>
          <UsergroupAddOutlined />
        </template>
        <a @click="isInsertUsersModalVisible = true">Add users</a>
      </a-timeline-item>
    </a-timeline>
    <a-modal :open="isInsertUsersModalVisible" title="Add users" @cancel="onCancelInsertUsers" @ok="onConfirmInsertUsers">
      <UserMultiSelect v-if="isInsertUsersModalVisible" style="margin: 25px 0" @select="onSelectUsers" />
    </a-modal>
  </div>
</template>

<script>
import PopconfirmButtonDelete from '@/core/components/PopconfirmButtonDelete.vue';
import { CloseOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons-vue';
import UserMultiSelect from '@userManagement/components/UserMultiSelect';

export default {
  props: ['role'],
  emits: ['insert', 'remove'],
  data() {
    return {
      hoveredUserId: null,
      selectedUserIdToRemove: null,
      isInsertUsersModalVisible: false,
      selectedUserIdListToInsert: [],
    };
  },
  components: {
    PopconfirmButtonDelete,
    UserMultiSelect,
    UsergroupAddOutlined,
    UserOutlined,
    CloseOutlined,
  },
  methods: {
    closeInsertUsersModal() {
      this.selectedUserIdListToInsert = [];
      this.isInsertUsersModalVisible = false;
    },
    onCancelInsertUsers() {
      this.closeInsertUsersModal();
    },
    onConfirmInsertUsers() {
      this.$emit('insert', this.selectedUserIdListToInsert);
      this.closeInsertUsersModal();
    },
    removeUser(userId) {
      this.$emit('remove', userId);
    },
    onSelectUsers(selectedUserIdList) {
      this.selectedUserIdListToInsert = selectedUserIdList;
    },
  },
};
</script>

<style scoped>
.user-full-name:hover {
  font-weight: 500;
  cursor: pointer;
}
</style>
