<template>
  <div>
    <div v-if="fieldStructureType === 'reference'">
      <a-select
        placeholder="Select subtenant"
        v-model:value="subtenantSlug"
        style="width: 100%; margin-bottom: 5px"
        :options="subtenantOptions"
        @change="
          getReferenceInstanceOptions();
          emitSelection();
        "
      >
      </a-select>
      <a-select
        v-model:value="inputValue"
        :options="referenceOptions"
        style="width: 100%"
        :loading="referenceOptionsLoading"
        @change="emitSelection"
        show-search
        :filter-option="filterOption"
      ></a-select>
    </div>

    <div v-else>
      <a-input v-model:value="inputValue" @input="emitSelection" />
    </div>
  </div>
</template>

<script>
// TODO: Implement Input for Array and Json
// TODO: Implement logic for field.choices
// TODO: Decide if this component is needed or if FieldWidget can be used instead
import { TenantsGetters } from '@tenants/shared/tenants.store';
import blueprintApi from '@dataSystem/api/blueprint.api';
import subtenantApi from '@tenants/api/subtenant.api';
import { FormBuilderActions, FormBuilderGetters } from '../../../formBuilder.store';

export default {
  props: ['fieldId', 'condition', 'fieldType', 'blueprintId'],
  emits: ['update:modelValue', 'expectedValue'],
  data() {
    return {
      inputValue: null,
      subtenantSlug: undefined,
      subtenantOptions: [],
      selectedFieldType: null,

      referenceOptions: [],
      referenceOptionsLoading: false,
    };
  },
  computed: {
    fieldStructureType() {
      if (!this.fieldId) {
        return 'not_selected';
      }
      if (this.fieldType) {
        return this.fieldType;
      }
      const field = FormBuilderGetters.getFieldInput(this.fieldId);
      return field.structure.type;
    },
    selectedBlueprintId() {
      if (this.fieldStructureType !== 'reference') {
        return 'not_reference';
      }

      const field = FormBuilderGetters.getFieldInput(this.fieldId);
      return field.structure.ruleset.blueprintId;
    },
  },
  async mounted() {
    await this.getAppSubtenants();
    this.selectedFieldType = this.fieldStructureType;

    if (this.condition.expectedValue !== undefined) {
      this.inputValue = this.condition.expectedValue;
    }
    if (this.blueprintId) {
      this.subtenantSlug = this.condition.subtenantSlug;
      await this.getReferenceInstanceOptions();
    }
  },
  watch: {
    fieldId() {
      if (this.fieldStructureType !== this.selectedFieldType || this.fieldStructureType === 'reference') {
        this.subtenantSlug = '';
        this.inputValue = '';
      }
      this.selectedFieldType = this.fieldStructureType;
      this.emitSelection();
    },
  },
  methods: {
    emitSelection() {
      this.$emit('expectedValue', {
        subtenantSlug: this.subtenantSlug ?? '',
        value: this.inputValue,
      });
    },

    async getAppSubtenants() {
      let subtenants = TenantsGetters.getAllSubtenants(); // pentru blueprint Global sa arate toti subtenatii si sa nu crape
      if (subtenants.length === 0) {
        subtenants = await subtenantApi.getAll();
      }
      this.subtenantOptions = subtenants.map(function (s) {
        return {
          label: s.name,
          value: s.slug,
        };
      });
    },

    async getReferenceInstanceOptions() {
      if (this.blueprintId) {
        this.referenceOptionsLoading = true;
        this.referenceOptions = [];
        await FormBuilderActions.setFieldInstances(this.fieldId, this.subtenantSlug, this.blueprintId);
        const instances = await FormBuilderGetters.getFieldInstances(this.fieldId, this.subtenantSlug, this.blueprintId);

        const bp = await blueprintApi.getOne(this.selectedBlueprintId);
        const bpDisplayFieldId = bp.blueprint.displayFieldId;
        this.referenceOptions = instances.map(function (i) {
          return {
            value: i._id,
            label: i[bpDisplayFieldId], // trebuie scos display field pt a afisa testul default
          };
        });

        this.referenceOptionsLoading = false;
      }
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    emitInputValue() {
      this.$emit('update:modelValue', this.inputValue);
    },
    onBooleanValueSelect(value) {
      this.inputValue = value === 'true';
    },
  },
};
</script>

<style></style>
